import React from "react";

const CustomCheckbox: React.FC<{
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}> = ({ checked = false, onChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  return (
    <label className="relative inline-block w-12 h-6 cursor-pointer">
      <input
        type="checkbox"
        className="opacity-0 w-0 h-0"
        checked={checked}
        onChange={handleChange}
      />
      <span
        className={`absolute top-0 left-0 right-0 bottom-0 rounded-full transition-all ${
          checked ? "bg-[#0F428D]" : "bg-gray-300"
        }`}
      >
        <span
          className={`absolute w-5 h-5 bg-white rounded-full shadow-md transform transition-transform ${
            checked ? "translate-x-6" : "translate-x-1"
        } top-0.5`}
        />
      </span>
    </label>
  );
};

export default CustomCheckbox;