import Button from "style-guide/component/Button";
import Typography from "style-guide/component/Typography";
import Weight from "./Weight";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { useEffect, useState } from "react";
import {
  getRfpAutoFillWeight,
  getRfpCategories,
  setRfpCategories,
} from "../../../../redux/slice/rfp";
import { RfpWeight } from "types/rfp";
import Loading from "style-guide/component/Loader";
import { useWebSocket } from "api/websocket";
import { InfoCircleOutlined } from "@ant-design/icons";

interface CategoryWeightProps {
  openSelectedStep: (step: string) => void;
}

const CategoriesWeight: React.FC<CategoryWeightProps> = ({
  openSelectedStep,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { clearMessage } = useWebSocket();
  const [weights, setWeights] = useState<RfpWeight>({
    extraction_categories: [],
  });
  const [isReasoningOpen, setIsReasoningOpen] = useState<boolean>(false);
  const [totalWeight, setTotalWeight] = useState<number>(0);
  const [autoFillTrigger, setAutoFillTrigger] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<"category" | "sub_category">(
    "category"
  );
  const { isLoading, isLoadingModal } = useSelector(
    (state: RootState) => state.applicationData
  );
  const { rfpWeight } = useSelector((state: RootState) => state.rfpData);

  const handleBack = () => {
    openSelectedStep("requirement");
  };

  const handleNext = async () => {
    const data = {
      extraction_categories: weights.extraction_categories,
      default_category_type: selectedType,
    };
    const resposne = await dispatch(setRfpCategories(data));
    if (resposne === 200) {
      openSelectedStep("budget");
    }
  };

  const handleAutoFillWeight = () => {
    setAutoFillTrigger((prev) => !prev);
    const updatedRfpWeight = {
      ...rfpWeight,
      default_category_type: selectedType,
    };

    dispatch(getRfpAutoFillWeight(updatedRfpWeight));
  };

  useEffect(() => {
    clearMessage();
    dispatch(getRfpCategories());
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center z-[9999] text-center">
          <Loading mainHeading="Calculating Weights" />
        </div>
      ) : isLoadingModal ? (
        <div className="fixed inset-0 flex items-center justify-center z-[9999]">
          <div
            className="w-10 h-10 border-4 border-t-blue-primary border-solid rounded-full animate-spin"
            role="status"
          />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <Typography
                variant="Heading 4"
                text="Tailor your results by defining weights allocation"
              />
              <Typography
                variant="LG"
                text="To tailor your results, please assign a percentage weights. The total must equal 100% for the system to accept your configuration."
              />
              <Typography
                variant="LG"
                text="You can also upload an existing weight allocation file or allow our system to automatically allocate weights."
              />
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row gap-4">
                <Button
                  type="Default"
                  buttonText="Upload Weight File"
                  className="py-0 px-1 rounded-md"
                />
                <Button
                  type="Default"
                  onClick={handleAutoFillWeight}
                  buttonText="Auto-Fill Weights"
                  className="py-0 px-1 rounded-md"
                />
              </div>
              {autoFillTrigger && (
                <div className="flex flex-row gap-2 justify-end items-center">
                  <InfoCircleOutlined className="text-[#00000099]" />
                  <div
                    onClick={() => setIsReasoningOpen(true)}
                    className="cursor-pointer"
                  >
                    <Typography
                      variant="Base"
                      text="See the reasoning behind weights."
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <Weight
            data={rfpWeight}
            totalWeight={totalWeight}
            setTotalWeight={setTotalWeight}
            setWeights={setWeights}
            weights={weights}
            setIsReasoningOpen={setIsReasoningOpen}
            isReasoningOpen={isReasoningOpen}
            autoFillTrigger={autoFillTrigger}
            setSelectedType={setSelectedType}
            selectedType={selectedType}
          />

          <div className="h-[72px] fixed bottom-0 left-0 right-0 flex flex-row justify-between items-center bg-white p-4 border-t-[#D9D9D9] border-t-[1px]">
            <Button
              type="Default"
              size="Large"
              icon={false}
              buttonText="Back"
              onClick={handleBack}
            />
            <Button
              type={isLoading && totalWeight !== 100 ? "Disabled" : "Primary"}
              size="Large"
              icon={false}
              buttonText="Next"
              onClick={handleNext}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CategoriesWeight;
