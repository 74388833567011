import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRfpComparativeAnalysis } from "../../redux/slice/summary";
import { AppDispatch, RootState } from "redux/store";
import Typography from "style-guide/component/Typography";
import Loading from "style-guide/component/Loader";
import { TrophyTwoTone } from "@ant-design/icons";

const NozmaRecommendation = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { comparativeOverview } = useSelector(
    (state: RootState) => state.summaryData
  );
  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );

  useEffect(() => {
    if (Object.keys(comparativeOverview).length === 0) {
      dispatch(getRfpComparativeAnalysis());
    }
  }, [comparativeOverview]);
  

  const formatBoldText = (text: string) => {
    const parts = text.split(/\*\*(.*?)\*\*/g); // Splitting text based on **...**
    return parts.map((part, i) =>
      i % 2 === 1 ? <strong key={i}>{part}</strong> : part
    );
  };

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999] text-center">
          <Loading />
        </div>
      )}
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <Typography variant="Heading 3" text="Nozma's Recommendation" />
          <Typography
            variant="Caption"
            text={`Last updated: ${
              comparativeOverview?.critical_cal_date
                ? new Date(
                    comparativeOverview.critical_cal_date
                  ).toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                  })
                : ""
            }`}
            className="text-[#00000099]"
          />
        </div>
        <div className="border rounded-md">
          <div className="bg-[#0000000A] p-6 flex flex-row gap-3">
            <TrophyTwoTone twoToneColor="#0F428D" />
            <Typography
              variant="Heading 5"
              text={comparativeOverview.recommended_company}
            />
          </div>
          <div className="grid grid-cols-2 gap-12 p-6">
            {comparativeOverview?.reasoning_and_comparative_advantages
              ?.key_comparative_advantages && (
              <div>
                <Typography variant="Base" text="Reasoning" strong />
                <Typography
                  variant="Base"
                  text={
                    comparativeOverview?.reasoning_and_comparative_advantages
                      ?.reason_for_recommended_vendor
                  }
                  className="text-justify"
                />
              </div>
            )}
            {comparativeOverview?.reasoning_and_comparative_advantages
              ?.key_comparative_advantages && (
              <div>
                <Typography
                  variant="Base"
                  text="Comparative Advantages"
                  strong
                />
                <ul className="list-none space-y-1">
                  {comparativeOverview?.reasoning_and_comparative_advantages?.key_comparative_advantages.map(
                    (item: any, index: number) => (
                      <li key={index} className="flex">
                        <span className="mr-2">•</span>
                        <Typography variant="Base" className="text-justify">
                          {formatBoldText(item)}
                        </Typography>
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}

            <div>
              <Typography variant="Base" text="Pricing" strong />
              <ul className="list-none space-y-1">
                {comparativeOverview?.pricing_analysis?.pricing_analysis
                  ?.filter(
                    (item: any) =>
                      item?.vendor_name ===
                      comparativeOverview?.recommended_company
                  )
                  ?.map((item: any) => {
                    const points = item?.bullets || item?.analysis || []; // Handle both keys
                    return points.map((point: string, index: number) => (
                      <li key={index} className="flex">
                        <span className="mr-2">•</span>
                        <Typography
                          variant="Base"
                          text={point}
                          className="text-justify"
                        />
                      </li>
                    ));
                  })}
              </ul>
            </div>
            {/* analysis */}
            {comparativeOverview?.risk_analysis?.risks && (
              <div>
                <Typography variant="Base" text="Risks" strong />
                <ul className="list-none space-y-1">
                  {comparativeOverview?.risk_analysis?.risks.map(
                    (item: any, index: any) => (
                      <li key={index} className="flex">
                        <span className="mr-2">•</span>
                        <Typography variant="Base" className="text-justify">
                          {formatBoldText(item)}
                        </Typography>
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NozmaRecommendation;
