import Menu from "style-guide/component/Menu";
import Typography from "style-guide/component/Typography";
import { useEffect, useState } from "react";
import RfpUpload from "./rfpUpload";
import Invite from "../invite";
import Requirement from "./criticalRequirement";
import ProgressBar from "style-guide/component/Progressbar";
// import UploadResponse from "../rfp_free/uploadResponse";
import Budget from "./budget";
import CategoryWeight from "./weight";
import ExistingSupplier from "./existingSupplier";

const RfpDefined = () => {
  const rfpDefinedKeyValue = {
    supplier: false,
    overview: false,
    invite: false,
    requirement: false,
    weight: false,
    upload: false,
    budget: false,
  };

  const [rfpDefinedStep, setRfpDefinedStep] = useState(rfpDefinedKeyValue);
  const intakeMark = localStorage.getItem("intake flow3");

  const progressStepMapping = [
    ["overview"],
    ["invite"],
    ["requirement", "weight", "upload", "budget", "supplier"],
  ];

  const progressStep = [
    "RFP Upload",
    "Invite Stakeholders",
    "Select the critical requirement",
    "Select Vendors",
  ];

  const getCurrentStepIndex = () => {
    for (let i = 0; i < progressStepMapping.length; i++) {
      if (
        progressStepMapping[i].some(
          (step) => rfpDefinedStep[step as keyof typeof rfpDefinedStep]
        )
      ) {
        return i + 1;
      }
    }
    return 0;
  };

  const updateSliderSteps = (name: string, keyValue: boolean) => {
    setRfpDefinedStep(rfpDefinedKeyValue);
    setRfpDefinedStep((prevState) => ({ ...prevState, [name]: keyValue }));
  };

  useEffect(() => {
    updateSliderSteps("overview", true);
    if (!intakeMark) {
      localStorage.setItem("intake flow3", "overview");
    }
  }, []);

  useEffect(() => {
    if (intakeMark === null) {
      updateSliderSteps("overview", true);
    } else if (intakeMark !== null) {
      updateSliderSteps(intakeMark, true);
    }
  }, []);

  const openSelectedStep = (step: string) => {
    localStorage.setItem("intake flow3", step);
    updateSliderSteps(step, true);
  };

  const currentStep = getCurrentStepIndex();

  return (
    <div className="overflow-x-auto w-full min-h-screen pb-[100px] pt-12 px-6 md:px-12 font-helvetica-neue flex flex-col gap-8">
      {/* Header Section */}
      <div className="flex flex-row justify-between">
        <Typography
          text="Nozma"
          variant="LG"
          className="text-blue-primary"
          strong
        />
        <Menu />
      </div>

      {/* Progress Bar */}
      <ProgressBar currentStep={currentStep} steps={progressStep} />

      {/* Step Components */}
      {rfpDefinedStep?.overview && (
        <RfpUpload openSelectedStep={openSelectedStep} />
      )}
      {rfpDefinedStep?.invite && <Invite openSelectedStep={openSelectedStep} />}
      {rfpDefinedStep?.requirement && (
        <Requirement openSelectedStep={openSelectedStep} />
      )}
      {rfpDefinedStep?.weight && (
        <CategoryWeight openSelectedStep={openSelectedStep} />
      )}
      {rfpDefinedStep?.budget && <Budget openSelectedStep={openSelectedStep} />}
      {rfpDefinedStep?.upload && (
        <RfpUpload openSelectedStep={openSelectedStep} />
      )}
      {rfpDefinedStep?.supplier && (
        <ExistingSupplier openSelectedStep={openSelectedStep} />
      )}
    </div>
  );
};

export default RfpDefined;
