import { InfoCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Button from "style-guide/component/Button";
import Input from "style-guide/component/Input";
import TextArea from "style-guide/component/Textarea";
import Typography from "style-guide/component/Typography";
import { RowData } from "types/rfp";

interface RequirementPopUpProps {
  setActiveRow: React.Dispatch<React.SetStateAction<RowData | null>>;
  activeRow: RowData | null;
  rows: RowData[];
  setRfptable: React.Dispatch<React.SetStateAction<RowData[]>>;
}

const RequirementPopUp: React.FC<RequirementPopUpProps> = ({
  setActiveRow,
  activeRow,
  rows,
  setRfptable,
}) => {
  const [questionType, setQuestionType] = useState("One choice question");

  const closeCriticalRequirement = () => {
    setActiveRow(null);
  };

  const saveCriticalRequirement = () => {
    if (activeRow) {
      const updatedRows = rows.map((row) => {
        if (row.question_number === activeRow.question_number) {
          return {
            ...row,
            is_critical: true,
            sample_answer: activeRow.sample_answer || null,
            question_type:
              questionType === "One choice question"
                ? "ONE_CHOICE_QUESTION"
                : "OPEN_QUESTION",
          };
        }
        return row;
      });

      setRfptable(updatedRows);
    }
    setActiveRow(null);
  };

  useEffect(() => {
    if (
      rows.find(
        (row) =>
          row.question_number === activeRow?.question_number &&
          activeRow?.sample_answer
      )
    ) {
      setQuestionType("Open Question");
    }
  }, []);

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[9999]">
      <div className="w-[480px] flex flex-col p-8 gap-8 rounded-lg shadow-md border bg-white">
        <div className="flex flex-col gap-4">
          <Typography variant="Heading 3" text="Critical Requirement" />
          <Typography
            variant="Base"
            text="To set a requirement as critical, please let us know what kind of answer are you looking for."
          />
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <Typography variant="Base" text="Your question" />
            <Input
              placeholder="Search"
              state="Default"
              size="Small"
              className="w-full"
              value={
                rows.find(
                  (row) => row.question_number === activeRow?.question_number
                )?.question || ""
              }
            />
          </div>
          <div>
            <Typography variant="Base" text="Question type" />
            <select
              value={questionType}
              onChange={(e) => setQuestionType(e.target.value)}
              className="w-full px-3 py-2 border rounded bg-white"
            >
              <option value="One choice question">Match to requirement</option>
              <option value="Open question">Match to sample answer</option>
            </select>
          </div>
          {questionType === "Open question" && (
            <div>
              <Typography variant="Base" text="Example response" />
              <TextArea
                value={activeRow?.sample_answer || ""}
                onChange={(e) =>
                  setActiveRow((prevRow) =>
                    prevRow
                      ? { ...prevRow, sample_answer: e.target.value }
                      : null
                  )
                }
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-between">
            <Button
              buttonText="Cancel"
              type="Default"
              onClick={closeCriticalRequirement}
            />
            <Button
              buttonText="Save"
              type="Primary"
              onClick={saveCriticalRequirement}
            />
          </div>
          <div className="flex items-center gap-2 text-[#00000099]">
            <InfoCircleOutlined className="w-4 h-4" />
            <Typography
              variant="SM"
              text="Cancelling will remove question as critical requirement."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequirementPopUp;
