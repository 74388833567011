import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReasoningAnalysis } from "../../redux/slice/summary";
import { AppDispatch, RootState } from "redux/store";
import Typography from "style-guide/component/Typography";
import { CloseOutlined } from "@ant-design/icons";

interface ReasoningPopUpProps {
  setIsReasoningOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReasoningPopUp: React.FC<ReasoningPopUpProps> = ({
  setIsReasoningOpen,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoadingModal } = useSelector(
    (state: RootState) => state.applicationData
  );
  const { reasoning } = useSelector((state: RootState) => state.summaryData);

  useEffect(() => {
    dispatch(getReasoningAnalysis());
  }, []);

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[9999]">
      <div className="w-[500px] h-[500px] overflow-auto flex flex-col p-6 gap-4 rounded-lg shadow-md border bg-white">
        <div className="flex flex-row items-center justify-between">
          <Typography variant="Heading 3" text="Evaluation Framework" />
          <CloseOutlined onClick={() => setIsReasoningOpen(false)} />
        </div>
        <div className="h-full">
          {isLoadingModal ? (
            <div className="flex items-center justify-center h-full">
              <div
                className="w-10 h-10 border-4 border-t-blue-primary border-solid rounded-full animate-spin"
                role="status"
              />
            </div>
          ) : (
            <ul className="list-disc pl-4">
              {reasoning?.reasoning_behind_analysis?.map(
                (item, index) => (
                  <li key={index} className="pl-4 p-1">
                    <Typography
                      variant="Base"
                      text={item.parameter_for_evaluation}
                      className="w-[90%]"
                      strong
                    />

                    <Typography
                      variant="Base"
                      text={item.analysis_reasoning}
                      className="w-[90%]"
                    />
                  </li>
                )
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReasoningPopUp;
