import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRfpBudgets, setRfpBudgets } from "../../../../redux/slice/rfp";
import { AppDispatch, RootState } from "redux/store";
import Button from "style-guide/component/Button";
import Typography from "style-guide/component/Typography";
import Input from "style-guide/component/Input"; // Ensure this is imported
import Loading from "style-guide/component/Loader";

interface BudgetProps {
  openSelectedStep: (step: string) => void;
}

const Budget: React.FC<BudgetProps> = ({ openSelectedStep }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { budget } = useSelector((state: RootState) => state.rfpData);
  const [totalBudget, setTotalBudget] = useState<string>("0");
  const { isLoading, isLoadingModal } = useSelector(
    (state: RootState) => state.applicationData
  );

  useEffect(() => {
    dispatch(getRfpBudgets());
  }, []);

  useEffect(() => {
    if (budget?.total_budget) {
      setTotalBudget(budget.total_budget.toLocaleString());
    }
  }, [budget]);

  const handleBack = () => {
    openSelectedStep("weight");
  };

  const handleNext = async () => {
    const numericBudget = Number(totalBudget.replace(/,/g, ""));
    const res = await dispatch(setRfpBudgets({ total_budget: numericBudget }));
    if (res === 200) {
      openSelectedStep("upload");
    }
  };

  const formatNumber = (value: string) => {
    const numericValue = value.replace(/,/g, "").replace(/\D/g, ""); // Remove non-numeric characters
    return numericValue ? Number(numericValue).toLocaleString() : "";
  };

  return isLoading ? (
    <div className="fixed inset-0 flex items-center justify-center z-[9999] text-center">
      <Loading />
    </div>
  ) : isLoadingModal ? (
    <div className="fixed inset-0 flex items-center justify-center z-[9999]">
      <div
        className="w-10 h-10 border-4 border-t-blue-primary border-solid rounded-full animate-spin"
        role="status"
      />
    </div>
  ) : (
    <>
      <div className="flex flex-col gap-4">
        <Typography
          variant="Heading 3"
          text="What is the budget for this solution?"
        />
        <Input
          type="text"
          className="w-[320px]"
          value={totalBudget}
          onChange={(e) => setTotalBudget(formatNumber(e.target.value))}
        />
      </div>

      <div className="h-[72px] fixed bottom-0 left-0 right-0 flex flex-row justify-between items-center bg-white p-4 border-t-[#D9D9D9] border-t-[1px]">
        <Button
          type="Default"
          size="Large"
          icon={false}
          buttonText="Back"
          onClick={handleBack}
        />
        <Button
          type="Primary"
          size="Large"
          icon={false}
          buttonText="Next"
          onClick={handleNext}
        />
      </div>
    </>
  );
};

export default Budget;
