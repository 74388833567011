import {
  CaretDownFilled,
  CaretRightFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  RightOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { categoryViewDetail } from "../../redux/slice/summary";
import { AppDispatch, RootState } from "redux/store";
import Button from "style-guide/component/Button";
import TabComponent from "style-guide/component/Tabs";
import Typography from "style-guide/component/Typography";
import { BsArrowsCollapse } from "react-icons/bs";

interface QuestionType {
  category: string;
  is_critical: boolean;
  question: string;
  question_type: string;
  sample_answer: string | null;
  sub_category: string;
  companies: Record<string, { answer: string; result: boolean }>;
}

function DynamicTable({
  data,
  selectedCompany,
}: {
  data: QuestionType[];
  selectedCompany: string;
}) {
  return (
    <div className="overflow-x-auto w-full custom-scrollbar">
      <div className="w-full">
        <table className="border border-[#D9D9D9] border-collapse w-full table-fixed">
          <thead>
            <tr>
              {/* Fixed width for the first column */}
              <th className="p-4 border border-[#D9D9D9] w-[300px] min-w-[300px] max-w-[300px] sticky left-0 bg-white">
                <Typography variant="Base" text="Criteria" strong />
              </th>
              {selectedCompany === "Show All"
                ? Object.keys(data[0]?.companies || {}).map(
                    (company, index) => (
                      <th
                        key={index}
                        className="p-4 border border-[#D9D9D9] w-[120px] min-w-[120px] max-w-[120px]"
                      >
                        <Typography variant="Base" text={company} strong />
                      </th>
                    )
                  )
                : selectedCompany && (
                    <th className="p-4 border border-[#D9D9D9] w-[120px] min-w-[120px] max-w-[120px]">
                      <Typography
                        variant="Base"
                        text={selectedCompany}
                        strong
                      />
                    </th>
                  )}
            </tr>
          </thead>
          <tbody>
            {data.map((question, rowIndex) => (
              <tr key={rowIndex}>
                {/* Fixed width for the first column */}
                <td className="p-4 border border-[#D9D9D9] w-[300px] min-w-[300px] max-w-[300px] sticky left-0 bg-white">
                  <Typography variant="Base" text={question.question} />
                </td>
                {selectedCompany === "Show All"
                  ? Object.entries(question.companies || {}).map(
                      ([, details], cellIndex) => (
                        <td
                          key={cellIndex}
                          className="p-4 border text-center border-[#D9D9D9] w-[120px] min-w-[120px] max-w-[120px]"
                        >
                          {details?.result ? (
                            <CheckCircleFilled className="text-[#52C41A]" />
                          ) : (
                            <CloseCircleFilled className="text-[#FF4D4F]" />
                          )}
                        </td>
                      )
                    )
                  : selectedCompany &&
                    question.companies[selectedCompany] && (
                      <td className="p-4 border text-center border-[#D9D9D9] w-[120px] min-w-[120px] max-w-[120px]">
                        {question.companies[selectedCompany]?.result ? (
                          <CheckCircleFilled className="text-[#52C41A]" />
                        ) : (
                          <CloseCircleFilled className="text-[#FF4D4F]" />
                        )}
                      </td>
                    )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function CompanyResponse({
  data,
  expandedQuestions,
  setExpandedQuestions,
  selectedCompany,
}: {
  data: QuestionType[];
  expandedQuestions: string[];
  setExpandedQuestions: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCompany: string;
}) {
  const toggleExpand = (questionText: string) => {
    setExpandedQuestions((prevExpanded) =>
      prevExpanded.includes(questionText)
        ? prevExpanded.filter((text) => text !== questionText)
        : [...prevExpanded, questionText]
    );
  };

  return (
    <div className="overflow-x-auto">
      <div className="min-w-[500px]">
        <div className="flex flex-col gap-4">
          {data.map((question) => {
            const companyResponse = question.companies[selectedCompany];

            return companyResponse ? (
              <div
                key={question.question}
                className="border border-[#D9D9D9] rounded-lg"
              >
                <div
                  className="p-4 bg-[#0000000A] flex flex-row gap-4 items-center cursor-pointer"
                  onClick={() => toggleExpand(question.question)}
                >
                  {expandedQuestions.includes(question.question) ? (
                    <CaretDownFilled className="text-[#00000099]" />
                  ) : (
                    <CaretRightFilled className="text-[#00000099]" />
                  )}
                  <div className="flex flex-row gap-2">
                    <Typography text="Question:" variant="Base" strong />
                    <Typography
                      text={question.question}
                      variant="Base"
                    />
                  </div>
                </div>
                {expandedQuestions.includes(question.question) && (
                  <div className="p-4">
                    <Typography text={companyResponse.answer} variant="Base" />
                  </div>
                )}
              </div>
            ) : null;
          })}
        </div>
      </div>
    </div>
  );
}

interface ViewDetailPopUpProps {
  category: string;
  subCategory: string;
  setViewDetail: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      company: string;
      category: string;
      subCategory: string;
    }>
  >;
  company: string;
}

const ViewDetailPopUp: React.FC<ViewDetailPopUpProps> = ({
  category,
  setViewDetail,
  subCategory,
  company,
}) => {
  const tabs = ["Overview"];
  const dispatch = useDispatch<AppDispatch>();
  const [activeTab, setActiveTab] = useState<string>("Overview");
  const { viewDetail } = useSelector((state: RootState) => state.summaryData);
  const companies = Object.keys(viewDetail?.company_info || {});
  const [showResponses, setShowResponses] = useState(true);
  const [expandedQuestions, setExpandedQuestions] = useState<string[]>([]);
  const [selectedCompanyTable, setSelectedCompanyTable] = useState(company);
  const [selectedCompanyResponse, setSelectedCompanyResponse] =
    useState(company);
  const [criticalQuestions, setCriticalQuestions] = useState<
    Record<string, QuestionType>
  >({});

  const { isLoadingModal } = useSelector(
    (state: RootState) => state.applicationData
  );

  useEffect(() => {
    if (viewDetail?.questions) {
      setCriticalQuestions(viewDetail.questions);
    }
  }, [viewDetail]);

  const expandAll = () => {
    setExpandedQuestions(
      Object.entries(criticalQuestions)
        .map(([questionNumber, questionData]) => ({
          questionNumber,
          ...questionData,
          companies: questionData.companies ?? [],
        }))
        .map((question) => question.question)
    );
  };

  const collapseAll = () => {
    setExpandedQuestions([]);
  };

  useEffect(() => {
    if (showResponses) {
      expandAll();
    } else {
      collapseAll();
    }
  }, [showResponses]);

  const renderTabContent = () => {
    switch (activeTab) {
      case "Overview":
        return (
          <>
            <Typography variant="Heading 4" text="Requirements" strong />
            <div className="flex flex-row gap-2">
              {["Show All", ...companies].map((company) => (
                <div
                  key={company}
                  className={`border rounded-[40px] px-4 py-1 w-fit cursor-pointer ${
                    selectedCompanyTable === company
                      ? "border-blue-primary"
                      : "border-[#D9D9D9]"
                  }`}
                  onClick={() => setSelectedCompanyTable(company)}
                >
                  <Typography
                    variant="Base"
                    text={company}
                    strong={selectedCompanyTable === company}
                  />
                </div>
              ))}
            </div>
            <DynamicTable
              selectedCompany={selectedCompanyTable}
              data={Object.entries(criticalQuestions).map(
                ([questionNumber, questionData]) => ({
                  questionNumber,
                  ...questionData,
                  companies: questionData.companies ?? [],
                })
              )}
            />
            <div className="flex flex-row justify-between">
              <Typography variant="Heading 4" text="Responses" strong />
              <Button
                icon
                iconComponent={<BsArrowsCollapse />}
                onClick={() => setShowResponses(!showResponses)}
              />
            </div>
            <div className="flex flex-row gap-2">
              {[...companies].map((company) => (
                <div
                  key={company}
                  className={`border rounded-[40px] px-4 py-1 w-fit cursor-pointer ${
                    selectedCompanyResponse === company
                      ? "border-blue-primary"
                      : "border-[#D9D9D9]"
                  }`}
                  onClick={() => setSelectedCompanyResponse(company)}
                >
                  <Typography
                    variant="Base"
                    text={company}
                    strong={selectedCompanyResponse === company}
                  />
                </div>
              ))}
            </div>
            <CompanyResponse
              expandedQuestions={expandedQuestions}
              setExpandedQuestions={setExpandedQuestions}
              selectedCompany={selectedCompanyResponse}
              data={Object.entries(criticalQuestions).map(
                ([questionNumber, questionData]) => ({
                  questionNumber,
                  ...questionData,
                  companies: questionData.companies ?? [],
                })
              )}
            />
          </>
        );
      default:
        return <div>Select a tab to view content.</div>;
    }
  };

  useEffect(() => {
    dispatch(categoryViewDetail({ sub_category: subCategory }));
  }, []);

  return (
    <div className="fixed inset-0 flex justify-end items-center z-[999]">
      <div className="w-[652px] h-full flex flex-col rounded-lg shadow-md border bg-white overflow-scroll">
        {/* header */}
        <div className="w-full h-fit px-6 p-4 flex flex-row justify-between items-center border-b border-[#0000000F]">
          <Button
            type="Text"
            buttonText="Collapse"
            icon
            className="text-[black]"
            onClick={() =>
              setViewDetail({
                open: false,
                category: "",
                subCategory: "",
                company: "",
              })
            }
            iconComponent={<RightOutlined />}
          />
        </div>
        <div className="w-full p-6 flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <Typography variant="Heading 3" text={subCategory} />
            <div>
              <div className="flex flex-row gap-2">
                <Typography
                  variant="Base"
                  text="Category:"
                  strong
                  className="text-[#00000099] w-[114px] h-[32px]"
                />
                <Typography variant="Base" text={category} />
              </div>
              <div className="flex flex-row gap-2">
                <Typography
                  variant="Base"
                  text="Scorers:"
                  strong
                  className="text-[#00000099] w-[114px] h-[32px]"
                />
                <Typography variant="Base" text={viewDetail?.user_name} />
              </div>
            </div>
            <TabComponent
              tabs={tabs}
              defaultActiveTab="Overview"
              onChange={(tab) => setActiveTab(tab)} // Handle tab change
            />
          </div>
          {isLoadingModal ? (
            <div className="flex items-center justify-center h-full">
              <div
                className="w-10 h-10 border-4 border-t-blue-primary border-solid rounded-full animate-spin"
                role="status"
              />
            </div>
          ) : (
            renderTabContent()
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewDetailPopUp;
