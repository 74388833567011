import React from "react";
import { allStyles } from "style-guide/styles/Text";
import clsx from "clsx";

interface TypographyProps {
  variant: string;
  strong?: boolean;
  underline?: boolean;
  italic?: boolean;
  delete?: boolean;
  className?: string;
  text?: string; // Keep this for backward compatibility
  children?: React.ReactNode; // Add this to allow nested content
}

const Typography: React.FC<TypographyProps> = ({
  text,
  children,
  variant,
  strong,
  underline,
  italic,
  className,
  delete: del,
}) => {
  const styleObject = allStyles.find((style) => style.name === variant);

  const style: React.CSSProperties = {
    fontFamily: "Helvetica Neue",
    fontSize: styleObject?.fontSize,
    lineHeight: styleObject?.lineHeight,
    fontWeight: strong ? "bold" : "normal",
    textDecoration: underline ? "underline" : del ? "line-through" : "none",
    fontStyle: italic ? "italic" : "normal",
  };

  return (
    <p style={{ ...style, whiteSpace: "pre-wrap" }} className={clsx(className)}>
      {children || text} {/* Render children if provided, otherwise fallback to text */}
    </p>
  );
};

export default Typography;
