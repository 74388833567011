import { useEffect, useState } from "react";
import Button from "style-guide/component/Button";
import ReviewVendorDetail from "./ExistingSupplier";
import Typography from "style-guide/component/Typography";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import {
  getExistingSupplier,
  setExistingSupplier,
} from "../../../../redux/slice/summary";
import { useNavigate } from "react-router-dom";
import Loading from "style-guide/component/Loader";

export interface VendorFormData {
  isExistingSupplier: boolean | null;
  activeMSA: boolean;
  supplierStatus: string;
  previousYearSpend: string;
  description: string;
}

export interface VendorFormErrors {
  isExistingSupplier?: string;
  supplierStatus?: string;
  activeMSA?: string;
  previousYearSpend?: string;
  description?: string;
}

interface ExistingSupplierProps {
  openSelectedStep: (step: string) => void;
}

const ExistingSupplier: React.FC<ExistingSupplierProps> = ({
  openSelectedStep,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<{[company: string]: VendorFormData;}>({});
  const [formErrors, setFormErrors] = useState<{[company: string]: VendorFormErrors;}>({});
  const { isLoading } = useSelector((state: RootState) => state.applicationData);


  const { existingSupplier } = useSelector(
    (state: RootState) => state.summaryData
  );

  const validateForm = () => {
    const errors: { [company: string]: VendorFormErrors } = {};
    Object.entries(formData).forEach(([company, data]) => {
      const companyErrors: VendorFormErrors = {};
      if (data.isExistingSupplier === null) {
        companyErrors.isExistingSupplier =
          "Please select if this is an existing supplier";
      }
      if (data.isExistingSupplier) {
        if (!data.supplierStatus) {
          companyErrors.supplierStatus = "Supplier status is required";
        }
        if (!data.previousYearSpend) {
          companyErrors.previousYearSpend = "Previous year spend is required";
        }
        if (!data.activeMSA) {
          companyErrors.activeMSA = "Active MSA is required";
        }
        if (!data.description.trim()) {
          companyErrors.description = "Description is required";
        }
      }

      if (Object.keys(companyErrors).length > 0) {
        errors[company] = companyErrors;
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleBack = () => {
    openSelectedStep("upload");
  };

  useEffect(() => {
    dispatch(getExistingSupplier());
  }, []);

  const handleNext = async () => {
    if (validateForm()) {
      const updatedExistingSuppliers = {
        ...existingSupplier.existing_supplier,
      };

      Object.keys(formData).forEach((companyName) => {
        if (updatedExistingSuppliers[companyName]) {
          updatedExistingSuppliers[companyName] = {
            ...updatedExistingSuppliers[companyName],
            is_existing: formData[companyName].isExistingSupplier ?? false,
            is_msa: formData[companyName].activeMSA,
            supplier_status: formData[companyName].supplierStatus,
            previous_year_spends: formData[companyName].previousYearSpend,
            description: formData[companyName].description,
          };
        }
      });
      const finalPayload = {
        existing_supplier: updatedExistingSuppliers,
      };
      const resposne = await dispatch(setExistingSupplier(finalPayload));
      if (resposne === 200) {
        navigate("/automation");
      }
    }
  };

  return (
    <>
      <div>
        <Typography variant="Heading 3" text="Review Vendor Details" />
        <Typography
          variant="LG"
          text="Take a moment to review the vendors, if they are an existing supplier, you’ll follow up by answering five questions."
        />
      </div>
      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center z-[9999] text-center">
          <Loading />
        </div>
      ) : (
        <ReviewVendorDetail
          setFormData={setFormData}
          formData={formData}
          formErrors={formErrors}
        />
      )}
      {/* Footer Section */}
      <div className="h-[72px] fixed bottom-0 left-0 right-0 flex flex-row justify-between items-center bg-white p-4 border-t-[#D9D9D9] border-t-[1px]">
        <Button
          type="Default"
          size="Large"
          buttonText="Back"
          onClick={handleBack}
        />
        <Button
          type="Primary"
          size="Large"
          buttonText="Next"
          onClick={handleNext}
        />
      </div>
    </>
  );
};

export default ExistingSupplier;
