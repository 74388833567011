import { configureStore } from "@reduxjs/toolkit";
import applicationReducer from "./slice/application";
import overviewReducer from "./slice/overview";
import templatesReducer from "./slice/templates";
import evaluationReducer from "./slice/evaluation";
import buildReducer from "./slice/build";
import rfpReducer from "./slice/rfp";
import summaryReducer from "./slice/summary";

export const store = configureStore({
  reducer: {
    applicationData: applicationReducer,
    overviewData: overviewReducer,
    templatesData: templatesReducer,
    evaluationData: evaluationReducer,
    buildData: buildReducer,
    rfpData: rfpReducer,
    summaryData: summaryReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
