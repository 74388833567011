import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/store";
import { api } from "api/axiosInterceptor";
import {
  decrementRequests,
  incrementRequests,
  setIsLoading,
  setShowToast,
} from "./application";
import {
  ISummaryState,
  RfpEvaluationResponse,
  ReasoningResponse,
  ExistingSupplierData,
} from "types/summary";

const initialState: ISummaryState = {
  projectDetails: {
    core_business_problem: "",
    critical_cal_date: "",
    recommended_company: "",
    rfp_title: "",
    user_name: "",
    company_info: {},
    questions: {},
  },
  reasoning: {
    reasoning_behind_analysis: [],
  },
  comparativeOverview: {},
  viewDetail: {},
  existingSupplier: {
    existing_supplier: {},
  },
};

const slice = createSlice({
  name: "application",
  initialState,
  reducers: {
    projectSummaryDetails(state, action: PayloadAction<RfpEvaluationResponse>) {
      state.projectDetails = action.payload;
    },
    analysisReasoning(state, action: PayloadAction<ReasoningResponse>) {
      state.reasoning = action.payload;
    },
    comparativeAnalysis(state, action) {
      state.comparativeOverview = action.payload;
    },
    getCategoryViewDetail(state, action) {
      state.viewDetail = action.payload;
    },
    getExistingSupplierDetail(
      state,
      action: PayloadAction<ExistingSupplierData>
    ) {
      state.existingSupplier = action.payload;
    },
  },
});

export const {
  projectSummaryDetails,
  analysisReasoning,
  comparativeAnalysis,
  getCategoryViewDetail,
  getExistingSupplierDetail,
} = slice.actions;

export default slice.reducer;

export function getProjectSummaryDetails() {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = `/rfp/question/crtitical-requirements/`;
      const response = await api.get(path);
      dispatch(projectSummaryDetails(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function getReasoningAnalysis() {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const path = `/rfp/reasoning/behind/analysis`;
      const response = await api.get(path);
      dispatch(analysisReasoning(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function getRfpComparativeAnalysis() {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = `/rfp/reasoning/comparative/analysis/`;
      const response = await api.get(path);
      dispatch(comparativeAnalysis(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function categoryViewDetail(data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const path = `/rfp/category/view/details/`;
      const response = await api.post(path, data);
      dispatch(getCategoryViewDetail(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function setExistingSupplier(data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = `/rfp/existing/supplier/`;
      const response = await api.post(path, data);
      // dispatch(getCategoryViewDetail(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function getExistingSupplier() {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = `/rfp/existing/supplier/`;
      const response = await api.get(path);
      dispatch(getExistingSupplierDetail(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}
