import React, { useState, ChangeEvent, DragEvent, useEffect } from "react";
import Typography from "style-guide/component/Typography";
import {
  InboxOutlined,
  CloseOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { setShowToast } from "../../../../redux/slice/application";
import {
  uploadFile,
  deleteFile,
  downloadUploadedFile,
  getFileList,
  uploadRfpFile,
} from "../../../../redux/slice/overview";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { Spin } from "antd";
import Button from "style-guide/component/Button";
import { IFile } from "types/overview";

interface UploadDocumentProps {
  uploadedFiles: IFile[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<IFile[]>>;
}

const UploadDocument: React.FC<UploadDocumentProps> = ({
  setUploadedFiles,
  uploadedFiles,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [popupFileId, setPopupFileId] = useState<string | null>(null);
  const { listOfFiles } = useSelector(
    (state: RootState) => state.overviewData
  );
  const [isDelete, setIsDelete] = useState(false);
  const pathName = window.location.pathname;
  const intakeMark = localStorage.getItem("intake flow3");

  useEffect(() => {
    dispatch(getFileList("RFP"));
  }, []);

  useEffect(() => {
    if (listOfFiles?.files) {
      const updatedFiles: IFile[] = listOfFiles.files
        .filter((apiFile): apiFile is IFile => Boolean(apiFile))
        .map((apiFile) => ({ ...apiFile, status: "accepted" }));

      setUploadedFiles((prevFiles) => [
        ...updatedFiles,
        ...prevFiles.filter((file) => file?.status === "pending"),
      ]);
    }
  }, [listOfFiles]);

  const sanitizeFileName = (fileName: string) =>
    fileName.replace(/[^a-zA-Z0-9.\s_-]/g, "");

  const validateFile = (file: File) => {
    const allowedExtensions = ["pdf", "docx", "csv", "xlsx", "ppt"];
    const maxFileSize = 2.5 * 1024 * 1024;
    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Only .pdf and .docx files are allowed.",
        })
      );
      return false;
    }

    if (file.size > maxFileSize) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "File size must not exceed 2.5MB.",
        })
      );
      return false;
    }

    return true;
  };

  const handleSelectedFiles = async (files: File[]) => {
    const sanitizedFiles = files.map((file) => {
      const sanitizedFileName = sanitizeFileName(file.name);
      return new File([file], sanitizedFileName, { type: file.type });
    });

    const validFiles = sanitizedFiles.filter(validateFile);

    const newFiles: IFile[] = validFiles.map((file) => ({
      file_id: `${Date.now()}-${Math.random()}`,
      display_name: file.name,
      content_type: file.type,
      file_name: file.name,
      file_type: "RFP",
      status: "pending",
    }));

    setUploadedFiles((prevFiles) => {
      const uniqueFiles = prevFiles.filter(
        (file) => !newFiles.some((newFile) => newFile.file_id === file.file_id)
      );
      return [...uniqueFiles, ...newFiles];
    });
    let result;
    if (pathName === "/rfp_defined") {
      result = await dispatch(uploadRfpFile("RFP", validFiles));
    } else {
      result = await dispatch(uploadFile("RFP", validFiles));
    }
    if (result?.status === 200) {
      dispatch(
        setShowToast({
          show: true,
          type: "success",
          toastMessage: "Files uploaded successfully!",
        })
      );
      setUploadedFiles((prevFiles) =>
        prevFiles.map((file) =>
          validFiles.some((validFile) => validFile.name === file.display_name)
            ? { ...file, status: "accepted" }
            : file
        )
      );
    } else {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Failed to upload files.",
        })
      );
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const files = Array.from(event.target.files);
    handleSelectedFiles(files);
  };

  const handleDrop = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    handleSelectedFiles(files);
  };

  const handleDragOver = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
  };

  const handleDelete = async (fileId: string) => {
    try {
      setIsDelete(true);
      setUploadedFiles((prev) =>
        prev.filter((file) => file.file_id !== fileId)
      );
      // if(uploadFileResult[0].file_id){
      // await dispatch(deleteFile(uploadFileResult[0].file_id, "RFP"));
      // } else {
      await dispatch(deleteFile(fileId, "RFP"));
      // }
    } catch {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      setIsDelete(false);
      setPopupFileId(null);
    }
  };

  const handleCancelDelete = () => {
    setPopupFileId(null);
  };

  const handleDownload = async (fileId: string) => {
    dispatch(downloadUploadedFile(fileId));
  };

  return (
    <>
      {isDelete && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999]">
          <div
            className="w-10 h-10 border-4 border-t-blue-primary border-solid rounded-full animate-spin"
            role="status"
          />
        </div>
      )}
      <div className="h-fit gap-2 flex flex-col justify-between p-4">
        <Typography
          text={
            pathName === "/rfp_defined" && intakeMark === "overview"
              ? `Upload your RFP, the one that was sent to the vendors.We will start with parsing the RFP.`
              : pathName === "/rfp_defined" && intakeMark === "upload"
                ? "Upload your RFP response documents from all the vendors."
                : "Upload your RFX, the one that was sent to the vendors, to create your evaluation template."
          }
          variant="Heading 3"
          className="text-black"
        />
        <Typography
          text={
            intakeMark === "overview"
              ? "We will parse the file to build a custom evaluation template."
              : "We will parse these files and populate in the evaluation template. "
          }
          variant="LG"
          className="text-black"
        />
      </div>
      <label
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className="h-[144px] w-full border-[1px] border-[#D9D9D9] border-dashed rounded-lg flex flex-col justify-center items-center cursor-pointer"
      >
        <InboxOutlined style={{ fontSize: "40px", color: "#0F428D" }} />
        <Typography
          text={`File Formats: PDF, Documents (DOC/DOCX), Spreadsheets (XLS/XLSX), Presentations (PPT/PPTX) \n Size: 2.5MB Max`}
          variant="LG"
          className="text-black text-center"
        />
        <input
          id="file-upload"
          type="file"
          onChange={handleFileChange}
          className="hidden"
        />
      </label>

      <div className="mt-4 flex flex-col gap-4">
        <Typography variant="Heading 5" text={`Documents uploaded`} />
        {uploadedFiles?.length > 0 ? (
          uploadedFiles?.map((file) => (
            <>
              <div
                key={file?.file_id}
                className="w-[376px] border-[1px] border-[#D9D9D9] flex flex-row justify-between p-2 rounded-lg"
              >
                <div
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() => handleDownload(file.file_id)}
                >
                  {file.status === "pending" ? (
                    <Spin size="small" />
                  ) : file.status === "accepted" ? (
                    <CheckCircleFilled className="text-[green]" />
                  ) : null}
                  <Typography
                    text={file?.display_name}
                    variant="LG"
                    className="text-black"
                  />
                </div>
                <div
                  className="text-red-500 cursor-pointer"
                  onClick={() => setPopupFileId(file.file_id)}
                >
                  <CloseOutlined style={{ fontSize: "16px" }} />
                </div>
              </div>
              {popupFileId && (
                <div className="w-[400px] absolute bg-white border border-gray-300 shadow-md p-4 rounded-lg z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-10 p-4">
                  <Typography
                    text="Are you sure you want to delete this file?"
                    variant="LG"
                  />
                  <div className="flex flex-row gap-2 justify-end">
                    <Button
                      type="Default"
                      buttonText="Cancel"
                      onClick={handleCancelDelete}
                    />
                    <Button
                      type="Primary"
                      buttonText="Delete"
                      onClick={() => handleDelete(file.file_id)}
                    />
                  </div>
                </div>
              )}
            </>
          ))
        ) : (
          <Typography
            text="No files uploaded yet."
            variant="SM"
            className="text-gray-500"
          />
        )}
      </div>
    </>
  );
};

export default UploadDocument;
