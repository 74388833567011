import { useEffect, useState } from "react";
import { CaretDownFilled, CaretRightFilled } from "@ant-design/icons";
import Typography from "style-guide/component/Typography";
import CustomCheckbox from "style-guide/component/CustomToggle";
import { VendorFormData, VendorFormErrors } from ".";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import Input from "style-guide/component/Input";
import TextArea from "style-guide/component/Textarea";

interface ReviewVendorDetailProps {
  formData: { [company: string]: VendorFormData };
  setFormData: React.Dispatch<
    React.SetStateAction<{ [company: string]: VendorFormData }>
  >;
  formErrors: { [company: string]: VendorFormErrors };
}

const ReviewVendorDetail: React.FC<ReviewVendorDetailProps> = ({
  formData,
  setFormData,
  formErrors,
}) => {
  const { existingSupplier } = useSelector(
    (state: RootState) => state.summaryData
  );

  const [openSupplier, setOpenSupplier] = useState<string | null>(null);

  useEffect(() => {
    if (existingSupplier?.existing_supplier) {
      const initialData: { [company: string]: VendorFormData } = {};
      Object.keys(existingSupplier.existing_supplier).forEach(
        (supplierName) => {
          const supplier = existingSupplier.existing_supplier[supplierName];
          initialData[supplierName] = {
            isExistingSupplier: supplier.is_existing,
            activeMSA: supplier.is_msa,
            supplierStatus: supplier.supplier_status || "",
            previousYearSpend: supplier.previous_year_spends || "",
            description: supplier.description || "",
          };
        }
      );
      setFormData(initialData);
    }
  }, [existingSupplier]);

  const handleChange = (
    company: string,
    field: keyof VendorFormData,
    value: any
  ) => {
    setFormData((prev) => ({
      ...prev,
      [company]: {
        ...prev[company],
        [field]: value,
      },
    }));
  };

  const handleSupplierSelect = (supplierName: string) => {
    setOpenSupplier(openSupplier === supplierName ? null : supplierName);
  };

  const formatNumber = (value: string) => {
    const numericValue = value.replace(/,/g, "").replace(/\D/g, ""); // Remove non-numeric characters
    return numericValue ? Number(numericValue).toLocaleString() : "";
  };

  return (
    <div className="w-full border border-[#F0F0F0] rounded-lg">
      {existingSupplier?.existing_supplier &&
        Object.keys(existingSupplier.existing_supplier).map((supplierName) => {
          const companyData = formData[supplierName] || {};
          const companyErrors = formErrors[supplierName] || {};

          return (
            <div key={supplierName} className="border-b-2 border-[#F0F0F0]">
              {/* Supplier Header */}
              <div
                className="p-6 bg-[#FAFAFA] flex flex-row gap-4 cursor-pointer"
                onClick={() => handleSupplierSelect(supplierName)}
              >
                {openSupplier?.includes(supplierName) ? (
                  <CaretDownFilled className="text-[#00000099]" />
                ) : (
                  <CaretRightFilled className="text-[#00000099]" />
                )}
                <Typography variant="LG" text={supplierName} />
                {companyData?.isExistingSupplier && (
                  <div className="border border-[#D9D9D9] bg-[#0000000F] px-2 rounded-[4px] flex justify-center items-center">
                    <Typography variant="SM" text="Existing Supplier" />
                  </div>
                )}
              </div>

              <div className="p-6 flex flex-col gap-4">
                {/* Supplier Details - Conditionally Render */}
                {openSupplier === supplierName && (
                  <>
                    <div className="flex flex-col gap-3 w-[320px]">
                      <div className="flex flex-row gap-1">
                        <span className="text-[red]">*</span>
                        <Typography
                          variant="Base"
                          text="Is this an existing supplier?"
                        />
                      </div>
                      <select
                        className="px-2 py-1 w-[200px] border rounded bg-white outline-none focus:ring-0 focus:border-gray-300"
                        value={
                          companyData.isExistingSupplier === null
                            ? ""
                            : companyData.isExistingSupplier.toString()
                        }
                        onChange={(e) =>
                          handleChange(
                            supplierName,
                            "isExistingSupplier",
                            e.target.value === "true"
                          )
                        }
                      >
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                      {companyErrors.isExistingSupplier && (
                        <Typography
                          variant="SM"
                          className="text-[red]"
                          text={companyErrors.isExistingSupplier}
                        />
                      )}
                    </div>
                    <div className="flex flex-col gap-4">
                      {/* Conditionally Render Additional Fields */}
                      {companyData.isExistingSupplier && (
                        <div className="flex flex-col gap-4">
                          {/* Active MSA Toggle */}
                          <div className="flex items-center py-2 gap-2">
                            <div className="flex flex-row gap-1">
                              <span className="text-[red]">*</span>
                              <Typography variant="Base" text="Active MSA" />
                            </div>
                            <CustomCheckbox
                              checked={companyData.activeMSA}
                              onChange={(isChecked) =>
                                handleChange(
                                  supplierName,
                                  "activeMSA",
                                  isChecked
                                )
                              }
                            />
                            {companyErrors.activeMSA && (
                              <Typography
                                variant="SM"
                                className="text-[red]"
                                text={companyErrors.activeMSA}
                              />
                            )}
                          </div>

                          {/* Supplier Status */}
                          <div className="flex flex-col gap-3 w-[320px]">
                            <div className="flex flex-row gap-1">
                              <span className="text-[red]">*</span>
                              <Typography
                                variant="Base"
                                text="Supplier Status"
                              />
                            </div>
                            <select
                              className="px-2 py-1 w-[200px] border rounded bg-white outline-none focus:ring-0 focus:border-gray-300"
                              value={companyData.supplierStatus}
                              onChange={(e) =>
                                handleChange(
                                  supplierName,
                                  "supplierStatus",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select</option>
                              <option value="preferred">Preferred</option>
                              <option value="qualified">Qualified</option>
                              <option value="non-qualified">
                                Non-Qualified
                              </option>
                            </select>
                            {companyErrors.supplierStatus && (
                              <Typography
                                variant="SM"
                                className="text-[red]"
                                text={companyErrors.supplierStatus}
                              />
                            )}
                          </div>

                          {/* Previous Year Spend */}
                          <div className="flex flex-col gap-3 w-[320px]">
                            <div className="flex flex-row gap-1">
                              <span className="text-[red]">*</span>
                              <Typography
                                variant="Base"
                                text="Previous Year Spend"
                              />
                            </div>
                            <Input
                              value={companyData.previousYearSpend}
                              className="px-2 py-1 w-full rounded bg-white outline-none focus:ring-0"
                              onChange={(e) =>
                                handleChange(
                                  supplierName,
                                  "previousYearSpend",
                                  formatNumber(e.target.value)
                                )
                              }
                              type="text"
                            />
                            {companyErrors.previousYearSpend && (
                              <Typography
                                variant="SM"
                                className="text-[red]"
                                text={companyErrors.previousYearSpend}
                              />
                            )}
                          </div>

                          {/* Description of Goods/Services */}
                          <div className="flex flex-col gap-3 w-[320px]">
                            <div className="flex flex-row gap-1">
                              <span className="text-[red]">*</span>
                              <Typography
                                variant="Base"
                                text="Description of goods/services currently procured"
                              />
                            </div>
                            <TextArea
                              className="px-2 py-1 w-full rounded bg-white outline-none focus:ring-0"
                              value={companyData.description}
                              onChange={(e) =>
                                handleChange(
                                  supplierName,
                                  "description",
                                  e.target.value
                                )
                              }
                            />
                            {companyErrors.description && (
                              <Typography
                                variant="SM"
                                className="text-[red]"
                                text={companyErrors.description}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ReviewVendorDetail;
