import React, { useState, useEffect, useRef } from "react";
import Typography from "./Typography";

interface TabComponentProps {
  tabs: string[]; // Array of tab names
  onChange?: (tab: string) => void; // Optional onChange callback prop
  defaultActiveTab?: string; // Optional default active tab
}

const TabComponent: React.FC<TabComponentProps> = ({
  tabs,
  onChange,
  defaultActiveTab,
}) => {
  const initialActiveTab = defaultActiveTab
    ? tabs.indexOf(defaultActiveTab)
    : 0; // Determine the initial active tab index
  const [activeTab, setActiveTab] = useState<number>(initialActiveTab);
  const [sliderStyle, setSliderStyle] = useState<React.CSSProperties>({});
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    // Adjust slider width and position based on the active tab
    if (tabRefs.current[activeTab]) {
      const { offsetLeft, offsetWidth } = tabRefs.current[activeTab]!;
      setSliderStyle({
        width: `${offsetWidth}px`,
        transform: `translateX(${offsetLeft}px)`,
      });
    }

    // Trigger the onChange callback if provided
    if (onChange) {
      onChange(tabs[activeTab]);
    }
  }, [activeTab, onChange, tabs]);

  return (
    <div className="relative">
      {/* Tab Headers */}
      <div className="flex flex-row gap-6 border-b border-gray-200">
        {tabs.map((tab, index) => (
          <button
            key={index}
            ref={(el) => (tabRefs.current[index] = el)} // Store ref for each tab
            onClick={() => setActiveTab(index)}
            className={`pb-2 ${
              activeTab === index ? "text-blue-primary" : "text-[#000000E5]"
            }`}
          >
            <Typography variant="LG" text={tab} />
          </button>
        ))}
      </div>

      {/* Slider */}
      <div
        className="absolute bottom-0 h-1 bg-blue-primary transition-transform duration-300"
        style={sliderStyle}
      />
    </div>
  );
};

export default TabComponent;
