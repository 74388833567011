import React, { useState } from "react";
import Button from "style-guide/component/Button";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import Loading from "style-guide/component/Loader";
import Tooltip from "style-guide/component/Tooltip";
import UploadRfpDocument from "./UploadDocument";

interface UploadResponseProps {
  openSelectedStep: (step: string) => void;
}

const UploadResponse: React.FC<UploadResponseProps> = ({
  openSelectedStep,
}) => {
  const [allFilesValid, setAllFilesValid] = useState<boolean>(false);
  const pathName = window.location.pathname;
  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );

  const handleNext = async () => {
    if (pathName === "/rfp_defined") {
      openSelectedStep("budget");
    } else {
      openSelectedStep("invite");
    }
  };

  const handleBack = () => {
    if (pathName === "/rfp_defined") {
      openSelectedStep("requirement");
    } else {
      openSelectedStep("weightDone");
    }
  };

  return (
    <>
      <UploadRfpDocument setAllFilesValid={setAllFilesValid} />

      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999]">
          <Loading />
        </div>
      )}

      {/* Footer Section */}
      <div className="h-[72px] fixed bottom-0 left-0 right-0 flex justify-between items-center bg-white p-4 border-t border-gray-300">
        <Button
          type="Default"
          size="Large"
          icon={false}
          buttonText="Back"
          onClick={handleBack}
        />
        <Tooltip
          text={
            isLoading
              ? "Please wait, loading is in progress."
              : "Ensure all files are valid to proceed."
          }
          position="left"
          visible={!allFilesValid}
        >
          <Button
            type={isLoading || !allFilesValid ? "Disabled" : "Primary"}
            size="Large"
            icon={false}
            buttonText="Next"
            onClick={handleNext}
          />
        </Tooltip>
      </div>
    </>
  );
};

export default UploadResponse;
