import {
  AuditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FilterFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import Typography from "style-guide/component/Typography";
import AssignScorePopUp from "./AssignScorePopUp";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { getProjectSummaryDetails } from "../../redux/slice/summary";
import Button from "style-guide/component/Button";
import Badge from "style-guide/component/Badge";
import ReasoningPopUp from "./AnalysisReasoningPopUp";
import Loading from "style-guide/component/Loader";
import Sort from "images/Sort.png";

interface QuestionType {
  category: string;
  is_critical: boolean;
  question: string;
  question_type: string;
  sample_answer: string | null;
  sub_category: string;
  companies: Record<string, { answer: string; result: boolean }>;
}

function DynamicTable({
  data,
  onAssignScore,
}: {
  data: QuestionType[];
  onAssignScore: (title: string) => void;
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState({
    sort: false,
    filter: false,
  });
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<QuestionType[]>(data);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleSort = (type: string) => {
    if (type === "sort") {
      if (selectedCategories.length === 0) {
        setFilteredData(data);
      } else {
        const filtered = data.filter((question) =>
          selectedCategories.includes(question.category)
        );
        setFilteredData(filtered);
      }
    } else {
      if (selectedFilter === "Critical requirement first") {
        setFilteredData((prevData) =>
          [...prevData].sort(
            (a, b) => (b.is_critical ? 1 : 0) - (a.is_critical ? 1 : 0)
          )
        );
      } else {
        setFilteredData(data);
      }
    }

    setIsDropdownOpen({ sort: false, filter: false });
  };

  useEffect(() => {
    if (selectedFilter) {
      handleSort("filter");
    }
  }, [selectedFilter]);

  const { projectDetails } = useSelector(
    (state: RootState) => state.summaryData
  );

  const companies = Object.keys(projectDetails?.company_info || {});
  const categories = Array.from(new Set(data.map((item) => item.category)));

  const toggleDropdown = () =>
    setIsDropdownOpen({
      sort: !isDropdownOpen.sort,
      filter: false,
    });

  const handleCategoryChange = (category: string) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const handleReset = () => {
    setFilteredData(data);
    setSelectedCategories([]);
    setIsDropdownOpen({ sort: false, filter: false });
    setSelectedFilter(null); // Reset the filter selection
  };

  return (
    <div className="w-full overflow-hidden">
      <div className="w-full max-w-[1120px] overflow-x-auto sm:px-4 md:px-6 lg:px-8">
        <table className="border border-[#F0F0F0] w-full border-collapse">
          <thead>
            <tr>
              <th className="p-4 border border-[#F0F0F0] shadow-[6px_0px_8px_-2px_rgba(0,0,0,0.1)] w-[280px] min-w-[280px] max-w-[280px] sticky">
                <div className="flex flex-row items-center justify-between relative">
                  <Typography variant="Base" text="Requirements" strong />
                  <div className="flex flex-row gap-3">
                    <img
                      src={Sort}
                      onClick={toggleDropdown}
                      className="text-[#00000073]"
                    />
                    {isDropdownOpen.sort && (
                      <div className="absolute h-fit w-[250px] top-8 left-0 bg-white shadow-lg border rounded-md p-2 z-10">
                        <div className="flex flex-col gap-2 overflow-auto h-[300px]">
                          {categories.map((category) => (
                            <label
                              key={category}
                              className="flex items-center gap-2 p-2 text-left"
                            >
                              <input
                                type="checkbox"
                                checked={selectedCategories.includes(category)}
                                onChange={() => handleCategoryChange(category)}
                              />
                              <Typography
                                variant="Base"
                                text={`${category} (${category
                                  .split(" ")
                                  .map((word) => word[0])
                                  .join("")})`}
                              />
                            </label>
                          ))}
                        </div>
                        <div className="flex flex-row gap-2 mt-2 border-t-[#D9D9D9] border-t-[1px] pt-2">
                          <Button
                            onClick={handleReset}
                            type={
                              selectedCategories.length > 0
                                ? "Default"
                                : "Disabled"
                            }
                            buttonText="Reset"
                            className="w-full"
                          />
                          <Button
                            onClick={() => handleSort("sort")}
                            type="Primary"
                            buttonText="OK"
                            className="w-full"
                          />
                        </div>
                      </div>
                    )}
                    <FilterFilled
                      className="h-3 w-3 text-[#00000073]"
                      onClick={() =>
                        setIsDropdownOpen({
                          sort: false,
                          filter: !isDropdownOpen.filter,
                        })
                      }
                    />
                    {isDropdownOpen.filter && (
                      <div className="absolute h-fit w-[250px] top-8 left-0 bg-white shadow-lg border rounded-md p-2 z-10">
                        {[
                          "Critical requirement first",
                          "Requirement type first",
                        ].map((item) => (
                          <div
                            key={item}
                            onClick={() => {
                              setSelectedFilter(item); // Set the selected filter
                            }}
                            className="p-2 hover:bg-gray-100 hover:text-black cursor-pointer rounded-md text-left"
                          >
                            <Typography variant="Base" text={item} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </th>
              {companies.map((company, index) => (
                <th
                  key={index}
                  className={`p-4 border border-[#F0F0F0] w-[390px] min-w-[390px] max-w-[390px] sticky ${
                    (projectDetails?.company_info?.[company]?.total || 0) > 75
                      ? "bg-[#D9F7BE]"
                      : (projectDetails?.company_info?.[company]?.total || 0) >=
                          50
                        ? "bg-[#FFF3CD]"
                        : "bg-[#F8D7DA]"
                  }`}
                >
                  <div className="flex flex-row justify-between item-center">
                    <Typography variant="Base" text={company} strong />
                    <Button
                      buttonText="Assign Score"
                      type="Primary"
                      icon
                      iconComponent={<AuditOutlined />}
                      onClick={() => onAssignScore(company)}
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-4 border border-[#F0F0F0] shadow-[6px_0px_8px_-2px_rgba(0,0,0,0.1)] w-[280px] min-w-[280px] max-w-[280px] sticky">
                <Typography variant="Base" text="Critical requirements met" />
              </td>
              {companies?.map((company, index) => {
                const hasCriticalRequirement = filteredData.some(
                  (item: any) => item.is_critical
                );

                return (
                  <td
                    key={index}
                    className={`p-4 border border-[#F0F0F0] w-[390px] min-w-[390px] max-w-[390px] sticky ${projectDetails?.company_info?.[company]?.total > 75 ? "bg-[#F6FFED]" : (projectDetails?.company_info?.[company]?.total || 0) >= 50 ? "bg-[#FFFBE6]" : "bg-[#FFF2F0]"}`}
                  >
                    {hasCriticalRequirement ? (
                      <Badge
                        text={
                          projectDetails?.company_info?.[company]
                            ?.critical_requirement_meet || "N/A"
                        }
                        status={
                          (projectDetails?.company_info?.[company]?.total ||
                            0) > 75
                            ? "success"
                            : (projectDetails?.company_info?.[company]?.total ||
                                  0) >= 50
                              ? "warning"
                              : "error"
                        }
                      />
                    ) : (
                      <Typography
                        variant="Base"
                        text="No critical requirement selected"
                      />
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="p-4 border border-[#F0F0F0] shadow-[6px_0px_8px_-2px_rgba(0,0,0,0.1)] w-[280px] min-w-[280px] max-w-[280px] sticky">
                <Typography variant="Base" text="Total" />
              </td>
              {companies?.map((company, index) => (
                <td
                  key={index}
                  className={`p-4 border border-[#F0F0F0] w-[390px] min-w-[390px] max-w-[390px] sticky ${projectDetails?.company_info?.[company]?.total > 75 ? "bg-[#F6FFED]" : (projectDetails?.company_info?.[company]?.total || 0) >= 50 ? "bg-[#FFFBE6]" : "bg-[#FFF2F0]"}`}
                >
                  <Badge
                    text={`${projectDetails?.company_info?.[company]?.total || 0}%`}
                    status={
                      (projectDetails?.company_info?.[company]?.total || 0) > 75
                        ? "success"
                        : (projectDetails?.company_info?.[company]?.total ||
                              0) >= 50
                          ? "warning"
                          : "error"
                    }
                  />
                </td>
              ))}
            </tr>
            {filteredData?.map((question, rowIndex) => (
              <tr key={rowIndex}>
                {/* Key Column (Requirement Name) */}
                <td className="p-4 border border-[#F0F0F0] shadow-[6px_0px_8px_-2px_rgba(0,0,0,0.1)] w-[280px] min-w-[280px] max-w-[280px] sticky align-top">
                  <div className="flex flex-row gap-2">
                    <Badge
                      text={question?.category
                        .split(" ")
                        .map((word) => word[0])
                        .join("")}
                      status="info"
                    />
                    {question?.is_critical === true && (
                      <Badge text="Critical Requirement" status="warning" />
                    )}
                  </div>
                  <div className="flex flex-row gap-2 mt-2">
                    <Typography
                      variant="Base"
                      text={`${(question as any).questionNumber}`}
                      strong
                    />

                    <Typography variant="Base" text={question?.question} />
                  </div>
                </td>

                {Object.entries(question.companies || {}).map(
                  ([, details], cellIndex) => (
                    <td
                      key={cellIndex}
                      className="p-4 border border-[#F0F0F0] w-[390px] align-top"
                    >
                      <div className="flex flex-col">
                        <div className="flex justify-end">
                          <Badge
                            className="h-fit px-1"
                            status={
                              details.result === true ? "success" : "error"
                            }
                            icon={
                              details.result === true ? (
                                <CheckCircleOutlined className="h-3 w-3" />
                              ) : (
                                <CloseCircleOutlined className="h-3 w-3" />
                              )
                            }
                          />
                        </div>
                        <Typography variant="Base" text={details.answer} />
                      </div>
                    </td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const AnalysisTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isScoreOpen, setIsScoreOpen] = useState<boolean>(false);
  const [isReasoningOpen, setIsReasoningOpen] = useState<boolean>(false);
  const [selectedTitle, setSelectedTitle] = useState<string>("");
  const [criticalQuestions, setCriticalQuestions] = useState<
    Record<string, QuestionType>
  >({});

  const { projectDetails } = useSelector(
    (state: RootState) => state.summaryData
  );
  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );

  useEffect(() => {
    if (projectDetails?.questions) {
      setCriticalQuestions(projectDetails.questions);
    }
  }, [projectDetails]);

  const handleAssignScore = (title: string) => {
    setSelectedTitle(title);
    setIsScoreOpen(true);
  };

  useEffect(() => {
    dispatch(getProjectSummaryDetails());
  }, []);

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999] text-center">
          <Loading />
        </div>
      )}
      <div className="flex flex-row gap-2 justify-end">
        <InfoCircleOutlined className="text-[#00000099]" />
        <Typography
          variant="Base"
          text="Want to learn more?"
          className="text-[#00000099]"
        />
        <div
          onClick={() => setIsReasoningOpen(true)}
          className="cursor-pointer"
        >
          <Typography
            variant="Base"
            text="See the reasoning behind analysis."
          />
        </div>
      </div>
      <div className="w-full flex flex-row">
        <DynamicTable
          onAssignScore={handleAssignScore}
          data={Object.entries(criticalQuestions).map(
            ([questionNumber, questionData]) => ({
              questionNumber,
              ...questionData,
              companies: questionData.companies ?? [],
            })
          )}
        />

        {isScoreOpen && (
          <AssignScorePopUp
            setIsScoreOpen={setIsScoreOpen}
            title={selectedTitle}
          />
        )}

        {isReasoningOpen && (
          <ReasoningPopUp setIsReasoningOpen={setIsReasoningOpen} />
        )}
      </div>
    </>
  );
};

export default AnalysisTable;
