import React from "react";
import Typography from "style-guide/component/Typography";
import { CloseOutlined } from "@ant-design/icons";

interface ReasoningPopUpProps {
  data: any;
  setIsReasoningOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Data {
  name: string;
  reasoning: string;
  weight: number;
}

const ReasoningBehindPopUp: React.FC<ReasoningPopUpProps> = ({
  setIsReasoningOpen,
  data,
}) => {
  const renderList = (data: Data[]) => (
    <ul className="list-disc pl-4">
      {data?.map((item, index) => (
        <li key={index} className="p-2">
          <div>
            <Typography
              variant="Base"
              text={`${item.name} - ${item.weight}%`}
              strong
            />

            <Typography
              variant="Base"
              text={item.reasoning}
              className="text-[#00000099] text-justify"
            />
          </div>
        </li>
      ))}
    </ul>
  );

  const renderTabContent = () => {
    return renderList(data);
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[9999]">
      <div className="w-[500px] h-[500px] overflow-auto flex flex-col p-6 gap-4 rounded-lg shadow-md border bg-white">
        <div className="flex flex-row items-center justify-between">
          <Typography variant="Heading 3" text="Weighting Rationale" />
          <CloseOutlined onClick={() => setIsReasoningOpen(false)} />
        </div>
        {renderTabContent()}{" "}
      </div>
    </div>
  );
};

export default ReasoningBehindPopUp;
