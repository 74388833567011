import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Typography from "./Typography";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { LogoutOutlined } from "@ant-design/icons";

interface MenuItem {
  title: string;
  icon: React.ReactNode;
  path?: string;
  subItems?: { title: string; path: string }[];
}

interface SidebarProps {
  toggleLogoutPopup: () => void;
  menuItems: MenuItem[];
}

const Sidebar: React.FC<SidebarProps> = ({ toggleLogoutPopup, menuItems }) => {
  const navigate = useNavigate();
  const [openDropdowns, setOpenDropdowns] = useState<{ [key: string]: boolean }>(
    {}
  );

  const toggleDropdown = (title: string) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  return (
    <div className="w-64 bg-[#1A1C4E] text-white h-screen p-4 flex flex-col gap-6">
      <div onClick={() => navigate(-1)}>
        <Typography
          variant="LG"
          text="Nozma"
          className="text-[#46CDEA] cursor-pointer"
          strong
        />
      </div>
      <nav className="h-full flex flex-col justify-between">
        <div>
          {menuItems.map((item) => (
            <div key={item.title}>
              {item.subItems ? (
                <>
                  <button
                    onClick={() => toggleDropdown(item.title)}
                    className="flex items-center justify-between w-full p-2 rounded hover:bg-[#E7ECF3] hover:text-blue-primary"
                  >
                    <div className="flex items-center gap-2">
                      {item.icon}
                      <Typography variant="LG" text={item.title} />
                    </div>
                    {openDropdowns[item.title] ? <FiChevronUp /> : <FiChevronDown />}
                  </button>
                  {openDropdowns[item.title] && (
                    <div className="ml-4 mt-2 space-y-2">
                      {item.subItems.map((subItem) => (
                        <Link
                          key={subItem.title}
                          to={subItem.path}
                          className="block py-2 px-2 rounded hover:bg-[#E7ECF3] hover:text-blue-primary"
                        >
                          {subItem.title}
                        </Link>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <Link
                  to={item.path || "#"}
                  className="flex items-center gap-2 py-2 px-2 rounded hover:bg-[#E7ECF3] hover:text-blue-primary"
                >
                  {item.icon}
                  <Typography variant="LG" text={item.title} />
                </Link>
              )}
            </div>
          ))}
        </div>

        {/* Settings & Logout */}
        <div className="flex flex-row justify-between">
          <Link
            to="/settings"
            className="block py-2 px-4 rounded hover:bg-[#E7ECF3] hover:text-blue-primary flex flex-row gap-2"
          >
            <Typography variant="LG" text="Settings" />
          </Link>
          <LogoutOutlined className="px-4 py-2 cursor-pointer" onClick={toggleLogoutPopup} />
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
