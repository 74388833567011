import React from "react";
import Typography from "./Typography";

interface BadgeProps {
  text?: string;
  status: "success" | "error" | "warning" | "info"; // Replace textColor with status
  icon?: React.ReactNode; // Icon can be passed as a prop
  className?: string; // Accept additional className
}

const Badge: React.FC<BadgeProps> = ({ text, status, icon, className }) => {
  const statusClasses = {
    success: "border-[#B7EB8F] border bg-[#F6FFED] text-[#135200]",
    error: "border-[#FFCCC7] border bg-[#FFF2F0] text-[#D9363E]",
    warning: "bg-[#FFFBE6] border text-[#AD6800] border-[#FFE58F]",
    info: "bg-[#F0F5FF] text-blue-primary border-[#ADC6FF] border",
  };

  return (
    <div
      className={`px-2 w-fit rounded-md ${statusClasses[status]} ${className}`}
      style={{ fontSize: "14px", fontWeight: 500 }}
    >
      {icon && <span className={` ${statusClasses[status].split(" ")[2]}`}>{icon}</span>}
      {text && <Typography variant="SM" text={text} />}
    </div>
  );
};

export default Badge;
