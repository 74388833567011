import React, { useState, ChangeEvent, ReactNode } from "react";
import clsx from "clsx";

type InputProps = {
  type?: string;
  placeholder?: string;
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void; // ✅ Added onBlur
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void; // ✅ Added onFocus
  state?: "Default" | "Hover" | "Focused";
  size?: "Small" | "Medium" | "Large";
  disabled?: boolean;
  theme?: "light" | "dark";
  className?: string;
  icon?: ReactNode;
  iconPosition?: "left" | "right";
  required?: boolean;
  errorMessage?: string;
};

const stateClasses = {
  light: {
    Default: "border-gray-300 bg-white text-black",
    Hover: "border-[#3E67A3] bg-white text-black",
    Focused: "border-[#0F428D] border-[2px] bg-white text-black shadow-[0px_0px_0px_2px_#0591FF1A]",
    Disabled: "border-gray-300 bg-gray-200 text-gray-500 cursor-not-allowed",
    Error: "border-red-500 bg-white text-black",
  },
  dark: {
    Default: "border-[#424242] bg-[#424242] text-white",
    Hover: "border-[#97BDF4] bg-[#424242] text-white",
    Focused: "border-[#7EADF2] bg-[#424242] border-[2px] text-white shadow-[0px_0px_0px_2px_#0591FF1A]",
    Disabled: "border-gray-600 bg-[#424242] text-gray-500 cursor-not-allowed",
    Error: "border-red-500 bg-[#424242] text-white",
  },
};

const sizeClasses = {
  Small: "text-sm px-2 py-1 rounded-md",
  Medium: "text-md px-4 py-2 rounded-md",
  Large: "text-lg px-6 py-3 rounded-lg",
};

const Input: React.FC<InputProps> = ({
  type = "text",
  placeholder = "",
  value,
  onChange,
  onKeyDown,
  onBlur, // ✅ Added to destructuring
  onFocus, // ✅ Added to destructuring
  state = "Default",
  size = "Medium",
  disabled = false,
  theme = "light",
  className = "",
  icon,
  iconPosition = "left",
  required = false,
  errorMessage = "This field is required",
}) => {
  const [inputState, setInputState] = useState<"Default" | "Hover" | "Focused" | "Error">(state);
  const [error, setError] = useState(false);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!disabled) {
      setInputState("Focused");
      setError(false);
      if (onFocus) onFocus(e); // ✅ Call user-defined onFocus
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!disabled) {
      if (required && !value) {
        setError(true);
        setInputState("Error");
      } else {
        setInputState("Default");
      }
      if (onBlur) onBlur(e); // ✅ Call user-defined onBlur
    }
  };

  return (
    <div className={clsx("relative", className)}>
      {icon && iconPosition === "left" && (
        <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
          {icon}
        </span>
      )}
      <input
        type={type}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={handleBlur} // ✅ Use updated handleBlur
        onFocus={handleFocus} // ✅ Use updated handleFocus
        placeholder={placeholder}
        className={clsx(
          "transition border w-full outline-none",
          icon && iconPosition === "left" ? "pl-10" : "pr-10",
          disabled
            ? stateClasses[theme].Disabled
            : stateClasses[theme][inputState],
          sizeClasses[size]
        )}
        disabled={disabled}
      />
      {icon && iconPosition === "right" && (
        <span className="absolute right-3 top-1/2 transform -translate-y-1/2">
          {icon}
        </span>
      )}
      {error && <p className="text-red-500 text-sm mt-1">{errorMessage}</p>}
    </div>
  );
};

export default Input;
