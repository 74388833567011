import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/store";
import { api } from "api/axiosInterceptor";
import { apiUrl } from "api/apiUrl";
import {
  decrementRequests,
  incrementRequests,
  setIsLoading,
  setShowToast,
} from "./application";
import {
  IFile,
  IFileResponse,
  IFileUploadData,
  IIntakeState,
  ISolutionAndObjective,
  IntakeObjectivesPayload,
} from "../../types/overview";
import { AxiosResponse } from "axios";

function getFileNameFromContentDisposition(
  response: AxiosResponse<Blob>
): string {
  const disposition = response.headers["content-disposition"];
  const fileNameMatch = disposition?.match(/filename="?(.+)"?/);
  return fileNameMatch ? fileNameMatch[1] : "downloaded_file";
}

const initialState: IIntakeState = {
  solutionAndObjective: {
    solution: "",
    objectives: [],
  },
  listOfFiles: {
    files: [],
    status: "",
  },
  uploadFileResult: [],
};

const slice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    getSolution(state, action: PayloadAction<ISolutionAndObjective>) {
      state.solutionAndObjective = action.payload;
    },
    getListOfFiles(state, action: PayloadAction<IFileResponse>) {
      state.listOfFiles = action.payload;
    },
    uploadFileResponse(state, action: PayloadAction<IFile[]>) {
      state.uploadFileResult = action.payload;
    },
  },
});

export const { getSolution, getListOfFiles, uploadFileResponse } =
  slice.actions;

export default slice.reducer;

export function getFileList(file_type: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = `files/${file_type}/`;
      const response = await api.get(path);
      dispatch(getListOfFiles(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function uploadFile(file_type: string, files: File[]) {
  return async (dispatch: AppDispatch) => {
    try {
      const path = `${apiUrl.upload_file}${file_type}/`;
      const formData = new FormData();

      files.forEach((file) => {
        formData.append(`files`, file);
      });

      const response = await api.post<IFileUploadData>(path, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        dispatch(getFileList(file_type));
        return { data: response.data, status: response.status };
      } else {
        throw new Error("Upload failed.");
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch(
          setShowToast({
            show: true,
            type: "error",
            toastMessage: error.message,
          })
        );
        dispatch(getFileList(file_type));
      }
    }
  };
}

export function uploadRfpFile(file_type: string, files: File[]) {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const path = `/file-uploads/requirements/${file_type}/`;
      const formData = new FormData();

      files.forEach((file) => {
        formData.append(`files`, file);
      });

      const response = await api.post<IFile[]>(path, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200 && file_type === "RFP") {
        dispatch(getFileList("RFP"));
      }
      dispatch(uploadFileResponse(response.data));
      return { data: response.data, status: response.status };
    } catch (error) {
      if (error instanceof Error) {
        dispatch(
          setShowToast({
            show: true,
            type: "error",
            toastMessage: error.message,
          })
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function getSolutionAndObjective() {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const path = apiUrl?.solutionAndObjective;
      const response = await api.get(path);
      dispatch(getSolution(response.data));
    } catch (error: any) {
      const status = error.response ? error.response.status : 500;
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );

      return status;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function deleteFile(file_id: string, file_type: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const path = `/files/delete/${file_id}/`;
      const resposne = await api.delete(path);
      if (resposne.status === 200 || resposne.status === 201) {
        if (file_type === "RFP_Response") {
          dispatch(getFileList(file_type));
        }
        dispatch(
          setShowToast({
            show: true,
            type: "success",
            toastMessage: "File deleted successfully!",
          })
        );
      }
      return resposne.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    }
  };
}

export function downloadUploadedFile(file_id: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = `/files/download/${file_id}/`;
      const response = await api.get(path, { responseType: "blob" });
      if (response.status === 200 || response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          getFileNameFromContentDisposition(response)
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch(
          setShowToast({
            show: true,
            type: "success",
            toastMessage: "File downloaded successfully!",
          })
        );
      }
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function setIntakeObjectives(data: IntakeObjectivesPayload) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = apiUrl?.updateSolutionObjectives;
      const response = await api.post(path, data);
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}
