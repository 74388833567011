import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/store";
import { api } from "api/axiosInterceptor";
import {
  decrementRequests,
  incrementRequests,
  setIsLoading,
  setShowToast,
} from "./application";
import {
  IRfpState,
  RfpBudget,
  RfpQuestion,
  RfpWeight,
  RowData,
} from "types/rfp";

const initialState: IRfpState = {
  rfpQuestion: {
    questions: {},
    extraction_categories: [],
    extraction_sub_categories: [],
  },
  rfpWeight: {
    extraction_categories: [],
    // default_category_type: null,
  },
  autoFillWeight: {
    extraction_categories: [],
    // default_category_type: null,
  },
  budget: {
    total_budget: 0,
  },
};

const slice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    rfpQuestionExtraction(state, action: PayloadAction<RfpQuestion>) {
      state.rfpQuestion = action.payload;
    },
    rfpCategories(state, action: PayloadAction<RfpWeight>) {
      state.rfpWeight = action.payload;
    },
    rfpAutoFillWeight(state, action: PayloadAction<RfpWeight>) {
      state.autoFillWeight = action.payload;
    },
    rfpBudget(state, action: PayloadAction<RfpBudget>) {
      state.budget = action.payload;
    },
  },
});

export const {
  rfpQuestionExtraction,
  rfpCategories,
  rfpBudget,
  rfpAutoFillWeight,
} = slice.actions;

export default slice.reducer;

export function getRfpQuestions() {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = `/rfp/question/extraction/`;
      const response = await api.get(path);
      dispatch(rfpQuestionExtraction(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function setRfpQuestions(data: Record<number, RowData>) {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const path = `/rfp/question/extraction/`;
      const response = await api.post(path, data);
      dispatch(rfpQuestionExtraction(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function getRfpCategories() {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = `/rfp/question/categories/`;
      const response = await api.get(path);
      dispatch(rfpCategories(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function getRfpAutoFillWeight(data: RfpWeight) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = `/rfp/autofill/weight/`;
      const response = await api.post(path, data);
      dispatch(rfpAutoFillWeight(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function setRfpCategories(data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const path = `/rfp/question/categories/`;
      const response = await api.post(path, data);
      // dispatch(rfpCategories(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function getRfpBudgets() {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const path = `rfp/budget/`;
      const response = await api.get(path);
      dispatch(rfpBudget(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function setRfpBudgets(totalBudget: RfpBudget) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = `rfp/budget/`;
      const response = await api.post(path, totalBudget);
      dispatch(rfpBudget(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}
