import React, { useEffect, useState } from "react";
import BuildTemplate from "./CriteriaRequirement";
import Button from "style-guide/component/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import {
  getCriteriaRquirements,
  getCriteriaWeights,
  setCriteriaRequirements,
  setWeights,
} from "../../../../redux/slice/templates";
import Loading from "style-guide/component/Loader";
import { ChipsData, WeightsData } from "../../../../types/templates";
import ProgressBarComponent from "style-guide/component/Progress";
import { useWebSocket } from "api/websocket";

interface TemplteProps {
  openSelectedStep: (step: string) => void;
}

const BuildTemplateFlow: React.FC<TemplteProps> = ({ openSelectedStep }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { message, clearMessage } = useWebSocket();
  const [criteriaWeight, setCriteriaWeights] = useState<WeightsData>({});
  const [chipsData, setChipsData] = useState<ChipsData>({});
  const [totalWeight, setTotalWeight] = useState<number>(0);
  const [currentComponent, setCurrentComponent] = useState<string | null>();
  const { isLoading, isLoadingModal } = useSelector(
    (state: RootState) => state.applicationData
  );
  const { criteria } = useSelector((state: RootState) => state.templatesData);
  const intakeMark: string | null = localStorage.getItem("intake");

  useEffect(() => {
    if (intakeMark) {
      setCurrentComponent(intakeMark);
    }
  }, [intakeMark]);

  const handleNext = async () => {
    if (currentComponent === "requirement") {
      const response = await dispatch(setCriteriaRequirements(chipsData));
      if (response === 200) {
        openSelectedStep("weight");
      }
    } else if (currentComponent === "weight") {
      openSelectedStep("weightDone");
    } else if (currentComponent === "weightDone") {
      const resposne = await dispatch(setWeights(criteriaWeight));
      if (resposne === 200) {
        openSelectedStep("upload");
      }
    }
  };

  const handleBack = () => {
    if (currentComponent === "requirement") {
      openSelectedStep("review");
    } else if (currentComponent === "weight") {
      openSelectedStep("requirement");
    } else {
      openSelectedStep("weight");
    }
  };

  useEffect(() => {
    const fetchCriteriaRequirement = async () => {
      const response = await dispatch(getCriteriaRquirements());

      if (response === 500 || response === 502) {
        dispatch(getCriteriaRquirements());
      }
    };

    fetchCriteriaRequirement();
  }, []);

  useEffect(() => {
    clearMessage();
    const fetchCriteriaWeights = async () => {
      if (currentComponent === "weightDone" && Object.keys(criteria).length) {
        const response = await dispatch(getCriteriaWeights());
        if (response === 500) {
          dispatch(getCriteriaWeights());
        }
      }
    };

    fetchCriteriaWeights();
  }, [criteria, currentComponent, dispatch]);

  return (
    <>
      {isLoadingModal && currentComponent === "weightDone" && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999] text-center">
          <Loading
            mainHeading="Generating weights"
            headingText="This will only take a few seconds."
            subText=""
          />
        </div>
      )}
      {isLoading ? (
        <>
          {message?.info?.type === 1 ? (
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50">
              <ProgressBarComponent
                steps={message?.task_list}
                currentStatus={message?.status_id}
                progress={message?.info?.percentage || 0}
                progressHeading="Generating Requirements for Each Section"
              />
            </div>
          ) : (
            <div className="fixed inset-0 flex items-center justify-center z-[9999] text-center">
              <Loading />
            </div>
          )}
        </>
      ) : (
        <BuildTemplate
          currentComponent={currentComponent}
          chipsData={chipsData}
          setChipsData={setChipsData}
          setCriteriaWeights={setCriteriaWeights}
          criteriaWeight={criteriaWeight}
          setTotalWeight={setTotalWeight}
          totalWeight={totalWeight}
        />
      )}

      {/* Footer Section */}
      <div className="h-[72px] fixed bottom-0 left-0 right-0 flex justify-between items-center bg-white p-4 border-t border-gray-300">
        <Button
          type="Default"
          size="Large"
          icon={false}
          buttonText="Back"
          onClick={handleBack}
        />
        <Button
          type={
            isLoading ||
            (currentComponent === "weightDone" && totalWeight !== 100)
              ? "Disabled"
              : "Primary"
          }
          size="Large"
          icon={false}
          buttonText={
            currentComponent === "requirement"
              ? "Next"
              : currentComponent === "weight"
                ? "Generate Weights"
                : "Next: Upload RFP Responses"
          }
          onClick={handleNext}
        />
      </div>
    </>
  );
};

export default BuildTemplateFlow;
