import Button from "style-guide/component/Button";
import Input from "style-guide/component/Input";
import TextArea from "style-guide/component/Textarea";
import Typography from "style-guide/component/Typography";

interface AssignScorePopUpProps {
  setIsScoreOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
}

const AssignScorePopUp: React.FC<AssignScorePopUpProps> = ({
  setIsScoreOpen,
  title,
}) => {
  const handleClose = () => {
    setIsScoreOpen(false);
  };

  const handleSave = () => {
    setIsScoreOpen(false);
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[9999]">
      <div className="w-[480px] flex flex-col p-8 gap-8 rounded-lg shadow-md border bg-white">
        <div className="flex flex-col gap-4">
          <Typography variant="Heading 3" text="Assign Score" />
          <Typography
            variant="Base"
            text="Assign a score to the Vendor and leave a note if necessary."
          />
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <Typography variant="Base" text="Vendor" />
            <Input
              placeholder="Search"
              state="Default"
              size="Small"
              className="w-full"
              value={title}
            />
          </div>
          <div>
            <Typography variant="Base" text="Section" />
            <Input
              placeholder="Search"
              state="Default"
              size="Small"
              className="w-full"
              value={"Technology"}
            />
          </div>
          <div>
            <Typography variant="Base" text="Score" />
            <select className="w-full px-3 py-2 border rounded bg-white">
              <option value="One choice question">Select Score</option>
              <option value="Open question">Open question</option>
            </select>
          </div>
          <div>
            <Typography variant="Base">
              Your Score <span>(optional)</span>
            </Typography>
            <TextArea />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-between">
            <Button buttonText="Cancel" type="Default" onClick={handleClose} />
            <Button buttonText="Save" type="Primary" onClick={handleSave} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignScorePopUp;
