import { UploadOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import Button from "style-guide/component/Button";
import Typography from "style-guide/component/Typography";
import NozmaRecommendation from "./NozmaRecommendation";
import VendorComparison from "./VendorComparision";
import TabComponent from "style-guide/component/Tabs";
import { useState } from "react";
import AnalysisTable from "./AnalysisTable";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const KeyValue = ({
  label,
  value,
  icon,
}: {
  label: string;
  value: string;
  icon?: React.ReactNode;
}) => (
  <div className="flex flex-row items-center gap-x-2">
    <Typography
      variant="Base"
      text={`${label} :`}
      strong
      className="text-[#00000099]"
    />
    {icon && (
      <div className="w-6 h-6 rounded-full bg-[#FFF0F6] text-[#780650] flex justify-center items-center">
        {icon}
      </div>
    )}
    <Typography variant="Base" text={value} />
  </div>
);

const MarketingAutomation = () => {
  const tabs = ["Overview", "Scorecard", "Response Matrix", "Team"];
  const [activeTab, setActiveTab] = useState<string>("Overview");
  const { comparativeOverview } = useSelector(
    (state: RootState) => state.summaryData
  );
  const renderTabContent = () => {
    switch (activeTab) {
      case "Overview":
        return (
          <>
            <NozmaRecommendation />
            <VendorComparison />
          </>
        );
      case "Response Matrix":
        return <AnalysisTable />;
      default:
        return <div>Select a tab to view content.</div>;
    }
  };

  return (
    <div className="p-8 flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          {/* Header Section */}
          <div className="flex flex-row justify-between">
            <Typography
              variant="Heading 2"
              text={comparativeOverview?.rfp_title}
            />
            <div className="flex flex-row gap-2">
              <Button
                type="Default"
                buttonText="Share"
                icon
                iconComponent={<UsergroupAddOutlined />}
              />
              <Button
                type="Default"
                buttonText="Upload"
                icon
                iconComponent={<UploadOutlined />}
              />
              <Button type="Disabled" buttonText="Create Report" />
            </div>
          </div>
          {/* Owner Section */}
          <div className="flex flex-row items-center gap-x-3">
            <KeyValue
              label="Owner"
              value={comparativeOverview.user_name}
              icon={comparativeOverview?.user_name?.split("")[0]?.toUpperCase()}
            />
            <Typography variant="Base" text="." strong />
            <KeyValue
              label="Last Updated"
              value={
                comparativeOverview?.critical_cal_date
                  ? new Date(
                      comparativeOverview.critical_cal_date
                    ).toLocaleDateString("en-US", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    })
                  : ""
              }
            />
          </div>
        </div>
        <TabComponent
          tabs={tabs}
          defaultActiveTab="Overview"
          onChange={(tab) => setActiveTab(tab)} // Handle tab change
        />
      </div>
      {renderTabContent()}
    </div>
  );
};

export default MarketingAutomation;
