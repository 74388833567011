import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import {
  CaretDownFilled,
  CaretRightFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  ExpandAltOutlined,
  MinusCircleFilled,
} from "@ant-design/icons";
import Badge from "style-guide/component/Badge";
import Typography from "style-guide/component/Typography";
import ViewDetailPopUp from "./ViewDetail";
import Button from "style-guide/component/Button";

const VendorComparison: React.FC = () => {
  const [openCategories, setOpenCategories] = useState<Record<string, boolean>>(
    {}
  );
  const [hoveredCell, setHoveredCell] = useState<{
    row: number | null;
    col: number | null;
    category: string | null;
  }>({ row: null, col: null, category: null });

  const [viewDetailOpen, setViewDetail] = useState<{
    open: boolean;
    category: string;
    subCategory: string;
    company: string;
  }>({ open: false, category: "", subCategory: "", company: "" });

  const { comparativeOverview } = useSelector(
    (state: RootState) => state.summaryData
  );

  const toggleCategory = (categoryName: string) => {
    setOpenCategories((prev) => ({
      ...prev,
      [categoryName]: !prev[categoryName],
    }));
  };

  let companies = Object.keys(comparativeOverview?.company_info || {});
  const recommendedCompany = comparativeOverview?.recommended_company;
  if (recommendedCompany) {
    companies = [
      recommendedCompany,
      ...companies.filter((c) => c !== recommendedCompany),
    ];
  }

  const categoryData =
    comparativeOverview?.vendor_category_level?.[0]?.data || [];

  const getBorderClass = (company: string) =>
    company === recommendedCompany ? "border-blue-primary" : "border-[#D9D9D9]";

  const renderBadge = (companyData: any, field: string) => {
    const requirementMet = companyData?.[field] || 0;
    const status =
      requirementMet > 75
        ? "success"
        : requirementMet >= 50
          ? "warning"
          : "error";
    return <Badge text={`${requirementMet}%`} status={status} />;
  };

  const renderSubCategoryResult = (subCategoryResult: boolean | null) => {
    if (subCategoryResult === true)
      return <CheckCircleFilled className="text-[#52C41A]" />;
    if (subCategoryResult === false)
      return <CloseCircleFilled className="text-[#FF4D4F]" />;
    return <MinusCircleFilled className="text-[orange]" />;
  };

  return (
    <div className="p-6">
      <div className="mb-4">
        <div className="flex flex-row justify-between">
          <Typography variant="Heading 3">Vendor Comparison</Typography>
          <Button buttonText="Export" type="Default" />
        </div>
        <Typography
          variant="Caption"
          className="text-[#00000099]"
          text={`Last updated: ${
            comparativeOverview?.critical_cal_date
              ? new Date(
                  comparativeOverview.critical_cal_date
                ).toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })
              : ""
          }`}
        />
      </div>

      <table className="border-separate border-spacing-x-4 border-transparent">
        <thead>
          <tr>
            <th className="p-0 w-[260px]" />
            {companies.map((company, index) => (
              <th key={index} className="w-[260px]">
                {company === recommendedCompany && (
                  <div className="bg-blue-primary border-l border-r border-blue-primary text-white rounded-t-lg p-2 w-full text-center">
                    <Typography text="Recommended" variant="SM" strong />
                  </div>
                )}
              </th>
            ))}
          </tr>
          <tr>
            <th className="p-4 w-[260px]" />
            {companies.map((company, index) => (
              <th
                key={index}
                className={`${company !== recommendedCompany && "border-t rounded-t-lg"} border-l border-r w-[260px] ${getBorderClass(company)}`}
              >
                <Typography
                  variant="Heading 4"
                  text={company}
                  strong
                  className="p-2"
                />
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className="p-4">
              <Typography variant="Base" text="Critical requirements" />
            </td>
            {companies.map((company, index) => {
              const companyData =
                comparativeOverview?.vendor_category_level?.find(
                  (vendor: any) => vendor.company_name === company
                );
              return (
                <td
                  key={index}
                  className={`p-4 border-l border-r  ${getBorderClass(company)}`}
                >
                  <Badge
                    className="border-none"
                    text={`${companyData?.critical_requirement_met} critical requirements met`}
                    status={
                      companyData?.total_requirement_met > 75
                        ? "success"
                        : companyData?.total_requirement_met >= 50
                          ? "warning"
                          : "error"
                    }
                  />
                </td>
              );
            })}
          </tr>

          <tr>
            <td className="p-4">
              <Typography variant="Base" text="Total" />
            </td>
            {companies.map((company, index) => {
              const companyData =
                comparativeOverview?.vendor_category_level?.find(
                  (vendor: any) => vendor.company_name === company
                );
              return (
                <td
                  key={index}
                  className={`p-4 border-l border-r  ${getBorderClass(company)}`}
                >
                  {renderBadge(companyData, "total_requirement_met")}
                </td>
              );
            })}
          </tr>

          <tr>
            <td className="p-4">
              <Typography variant="Base" text="Price" />
            </td>
            {companies.map((company, index) => {
              const companyData =
                comparativeOverview?.vendor_category_level?.find(
                  (vendor: any) => vendor.company_name === company
                );
              return (
                <td
                  key={index}
                  className={`p-4 border-l border-r  ${getBorderClass(company)}`}
                >
                  <Typography variant="Base" text={companyData?.tco} />
                </td>
              );
            })}
          </tr>

          <tr>
            <td className="p-4">
              <Typography variant="Base" text="Existing supplier?" />
            </td>
            {companies.map((company, index) => {
              const companyData =
                comparativeOverview?.vendor_category_level?.find(
                  (vendor: any) => vendor.company_name === company
                );
              return (
                <td
                  key={index}
                  className={`p-4 border-l border-r  ${getBorderClass(company)}`}
                >
                  {companyData?.existing_supplier ? (
                    <CheckCircleFilled className="text-[#52C41A]" />
                  ) : (
                    "-"
                  )}
                </td>
              );
            })}
          </tr>

          {categoryData.map((category: any, rowIndex: number) => {
            const subCategories = Object.keys(category).filter(
              (key) =>
                key !== "category_name" && key !== "category_requirement_met"
            );
            return (
              <React.Fragment key={rowIndex}>
                <tr
                  className={`${
                    rowIndex === categoryData.length - 1 &&
                    !openCategories[category.category_name]
                      ? "border-b rounded-b-lg"
                      : ""
                  }`}
                >
                  <td>
                    <div
                      onClick={() => toggleCategory(category.category_name)}
                      className="flex flex-row gap-4"
                    >
                      {openCategories[category.category_name] ? (
                        <CaretDownFilled className="text-[#00000099]" />
                      ) : (
                        <CaretRightFilled className="text-[#00000099]" />
                      )}
                      <Typography
                        variant="Base"
                        text={category.category_name}
                      />
                    </div>
                  </td>
                  {companies.map((company, index) => {
                    const companyData =
                      comparativeOverview?.vendor_category_level?.find(
                        (vendor: any) => vendor.company_name === company
                      );
                    const categoryInfo = companyData?.data?.find(
                      (cat: any) => cat.category_name === category.category_name
                    );

                    const subCategories = Object.values(
                      categoryInfo || {}
                    ).filter((value: any) => typeof value === "object");

                    let checkCount = 0;
                    let closeCount = 0;
                    let minusCount = 0;

                    subCategories.forEach((sub: any) => {
                      // console.log("🚀 ~ subCategories.forEach ~ sub:", sub)
                      if (sub?.sub_category_result === true) checkCount++;
                      else if (sub?.sub_category_result === false) closeCount++;
                      else minusCount++;
                    });

                    let status: "success" | "warning" | "error" = "warning";
                    if (checkCount > closeCount && checkCount > minusCount) {
                      status = "success";
                    } else if (
                      closeCount > checkCount &&
                      closeCount > minusCount
                    ) {
                      status = "error";
                    }

                    return (
                      <td
                        key={index}
                        onMouseEnter={() =>
                          setHoveredCell({
                            row: rowIndex,
                            col: index,
                            category: category.category_name,
                          })
                        }
                        onMouseLeave={() =>
                          setHoveredCell({
                            row: null,
                            col: null,
                            category: null,
                          })
                        }
                        className={`p-4 border-l border-r ${getBorderClass(company)} ${
                          rowIndex === categoryData.length - 1 &&
                          !openCategories[category.category_name]
                            ? "border-b rounded-b-lg"
                            : ""
                        }`}
                      >
                        <div className="flex flex-row gap-2 justify-between h-fit min-h-[32px]">
                          <Badge
                            className="border-none h-fit"
                            status={status}
                            text={
                              categoryInfo?.category_requirement_met || "N/A"
                            }
                          />
                        </div>
                      </td>
                    );
                  })}
                </tr>

                {openCategories[category.category_name] &&
                  subCategories?.map((sub, subIndex) => (
                    <tr
                      key={`${rowIndex}-${subIndex}`}
                      className={`${
                        rowIndex === categoryData.length - 1 &&
                        subIndex === subCategories.length - 1
                          ? "border-b rounded-b-lg"
                          : ""
                      }`}
                    >
                      <td className="p-4 pl-6">
                        <Typography variant="Base" text={sub} />
                      </td>
                      {companies.map((company, index) => {
                        const companyData =
                          comparativeOverview?.vendor_category_level?.find(
                            (vendor: any) => vendor.company_name === company
                          );
                        const categoryInfo = companyData?.data?.find(
                          (cat: any) =>
                            cat.category_name === category.category_name
                        );
                        const subCategoryResult =
                          categoryInfo[sub]?.sub_category_result;

                        return (
                          <td
                            key={index}
                            onMouseEnter={() =>
                              setHoveredCell({
                                row: rowIndex,
                                col: index,
                                category: sub,
                              })
                            }
                            onMouseLeave={() =>
                              setHoveredCell({
                                row: null,
                                col: null,
                                category: null,
                              })
                            }
                            className={`py-2 px-4 border-l border-r ${getBorderClass(company)} 
                            ${
                              hoveredCell.category === sub &&
                              hoveredCell.col === index &&
                              "bg-[#0000000F]"
                            }
                            ${
                              rowIndex === categoryData.length - 1 &&
                              subIndex === subCategories.length - 1
                                ? "border-b rounded-b-lg"
                                : ""
                            }`}
                          >
                            <div className="flex flex-row justify-between h-[32px]">
                              {renderSubCategoryResult(subCategoryResult)}
                              {hoveredCell.category === sub &&
                                hoveredCell.col === index && (
                                  <Button
                                    type="Text"
                                    buttonText="View details"
                                    icon
                                    iconComponent={<ExpandAltOutlined />}
                                    onClick={() =>
                                      setViewDetail({
                                        open: true,
                                        company: company,
                                        subCategory: sub,
                                        category: category.category_name,
                                      })
                                    }
                                  />
                                )}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {viewDetailOpen.open && (
        <ViewDetailPopUp
          company={viewDetailOpen.company}
          subCategory={viewDetailOpen.subCategory}
          category={viewDetailOpen.category}
          setViewDetail={setViewDetail}
        />
      )}
    </div>
  );
};

export default VendorComparison;
