import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "redux/store";
import Button from "style-guide/component/Button";
import Loading from "style-guide/component/Loader";
import { IFile } from "types/overview";
import UploadDocument from "components/intake/rfp_free/overview/UploadDocument";
import UploadRfpResponse from "./UploadRfp";
import { useWebSocket } from "api/websocket";
import ProgressBarComponent from "style-guide/component/Progress";
import { uploadRfpFile } from "../../../../redux/slice/overview";

interface RfpUploadProps {
  openSelectedStep: (step: string) => void;
}

const RfpUpload: React.FC<RfpUploadProps> = ({ openSelectedStep }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { message } = useWebSocket();
  const intakeMark = localStorage.getItem("intake flow3");
  const [test, setTest] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
  const [click, setClick] = useState<boolean>(false);

  const { isLoading, isLoadingModal } = useSelector(
    (state: RootState) => state.applicationData
  );

  const handleBack = () => {
    if (intakeMark === "upload") {
      openSelectedStep("budget");
    } else {
      navigate(-1);
    }
  };

  const handleNext = async () => {
    if (intakeMark === "upload") {
      setClick(true);
      if (test.length > 0) {
        const result = await dispatch(uploadRfpFile("RFP_Response", test));
        if (result?.status === 200) openSelectedStep("supplier");
      } else {
        openSelectedStep("supplier");
      }
    } else {
      openSelectedStep("invite");
    }
  };

  useEffect(() => setUploadedFiles([]), []);

  return (
    <>
      {click && (!message || Object.keys(message).length === 0) && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999]">
          <div
            className="w-10 h-10 border-4 border-t-blue-primary border-solid rounded-full animate-spin"
            role="status"
          />
        </div>
      )}
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999]">
          <Loading
            mainHeading={isLoadingModal ? "Analyzing your File" : undefined}
          />
        </div>
      )}
      {isLoadingModal && message?.info?.type === 1 ? (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50">
          <ProgressBarComponent
            steps={message?.task_list}
            message={message.message}
            currentStatus={message?.status_id}
            progress={message?.info?.percentage || 0}
            progressHeading={message?.info?.progressbar}
          />
        </div>
      ) : (
        <>
          {/* Dynamic Component */}
          {intakeMark === "overview" ? (
            <div className="flex-grow">
              <UploadDocument
                setUploadedFiles={setUploadedFiles}
                uploadedFiles={uploadedFiles}
              />
            </div>
          ) : (
            <UploadRfpResponse
              setUploadedFiles={setUploadedFiles}
              uploadedFiles={uploadedFiles}
              setTest={setTest}
            />
          )}

          {/* Footer Section */}
          <div className="h-[72px] fixed bottom-0 left-0 right-0 flex flex-row justify-between items-center bg-white p-4 border-t-[#D9D9D9] border-t-[1px]">
            <Button
              type="Default"
              size="Large"
              buttonText="Back"
              onClick={handleBack}
            />
            <Button
              type={
                (!isLoading || !isLoadingModal) && uploadedFiles?.length > 0
                  ? "Primary"
                  : "Disabled"
              }
              size="Large"
              buttonText="Next"
              onClick={handleNext}
            />
          </div>
        </>
      )}
    </>
  );
};

export default RfpUpload;
