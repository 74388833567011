import React, { useEffect, useState } from "react";
// import Badge from "style-guide/component/Badge";
import Input from "style-guide/component/Input";
import Typography from "style-guide/component/Typography";
import { RfpWeight } from "types/rfp";
import ReasoningBehindPopUp from "./ReasoningBehind";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

interface WeightProps {
  data: RfpWeight;
  totalWeight: number;
  weights: RfpWeight;
  autoFillTrigger: boolean;
  setWeights: React.Dispatch<React.SetStateAction<RfpWeight>>;
  setTotalWeight: React.Dispatch<React.SetStateAction<number>>;
  setIsReasoningOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isReasoningOpen: boolean;
  selectedType: string;
  setSelectedType: React.Dispatch<React.SetStateAction<"category" | "sub_category">>;
}

const Weight: React.FC<WeightProps> = ({
  data,
  totalWeight,
  setTotalWeight,
  weights,
  setWeights,
  autoFillTrigger,
  isReasoningOpen,
  selectedType,
  setSelectedType,
  setIsReasoningOpen,
}) => {
  const [error, setError] = useState<string>("");
  const [focusedField, setFocusedField] = useState<number | null>(null);

  const { autoFillWeight } = useSelector((state: RootState) => state.rfpData);

  useEffect(() => {
    setWeights(() => {
      return {
        extraction_categories: (autoFillTrigger
          ? autoFillWeight
          : data
        ).extraction_categories.map((category) => {
          const categoryName = Object.keys(category)[0];
          return {
            [categoryName]: {
              weight: category[categoryName].weight,
              sub_category: Object.fromEntries(
                Object.entries(category[categoryName].sub_category).map(
                  ([subCategoryName, subCategoryData]) => [
                    subCategoryName,
                    {
                      weight: subCategoryData.weight,
                      reasoning: subCategoryData.reasoning || "",
                    },
                  ]
                )
              ),
              reasoning: category[categoryName].reasoning,
            },
          };
        }),
      };
    });
  }, [data, autoFillTrigger, autoFillWeight]);

  useEffect(() => {
    let categoryTotal = 0;
    let subCategoryTotal = 0;

    weights?.extraction_categories?.forEach((category) => {
      const categoryName = Object.keys(category)[0];
      categoryTotal += Number(category[categoryName].weight) || 0;

      Object.values(category[categoryName].sub_category).forEach((sub) => {
        subCategoryTotal += Number(sub.weight) || 0;
      });
    });

    if (selectedType === "category") {
      setTotalWeight(categoryTotal);
    } else {
      setTotalWeight(subCategoryTotal);
    }

    setError(
      (categoryTotal || subCategoryTotal) > 100
        ? "Total weight must not exceed 100%"
        : ""
    );
  }, [weights, selectedType]);

  const handleWeightChange = (
    categoryIndex: number,
    newWeight: number,
    type: "category" | "sub_category",
    subCategoryName?: string
  ) => {
    setWeights((prevWeights) => {
      if (!prevWeights) return prevWeights;

      return {
        extraction_categories: prevWeights.extraction_categories.map(
          (category, i) => {
            if (i !== categoryIndex) return category;

            const categoryName = Object.keys(category)[0];

            if (type === "category") {
              return {
                [categoryName]: {
                  ...category[categoryName],
                  weight: Math.max(newWeight, 0).toString(),
                },
              };
            } else if (subCategoryName) {
              return {
                [categoryName]: {
                  ...category[categoryName],
                  sub_category: {
                    ...category[categoryName].sub_category,
                    [subCategoryName]: {
                      weight: Math.max(newWeight, 0).toString(),
                      reasoning: prevWeights.extraction_categories[categoryIndex][categoryName].sub_category[subCategoryName]?.reasoning || ""
                    },
                    
                  },
                },
              };
            }

            return category;
          }
        ),
      };
    });
  };
  const getFilteredData = () => {
    const categoryData =
      weights?.extraction_categories?.map((category, categoryIndex) => {
        const categoryName = Object.keys(category)[0]; // Extract category name
        const categoryInfo = category[categoryName]; // Get category object

        return {
          number: (categoryIndex + 1).toString(),
          name: categoryName, // Category name
          weight: categoryInfo.weight || "",
          category: "",
          reasoning: categoryInfo.reasoning || "",
          onChange: (value: number) =>
            handleWeightChange(categoryIndex, value, "category"),
        };
      }) || [];

    const subCategoryData =
      weights?.extraction_categories?.flatMap((category, categoryIndex) => {
        const categoryName = Object.keys(category)[0]; // Extract category name
        const subCategories = category[categoryName].sub_category || {}; // Get subcategories

        return Object.entries(subCategories).map(
          ([subCategoryName, subInfo], subIndex) => ({
            category: categoryName, // Parent category name
            number: (subIndex + 1 + categoryData.length).toString(), // Adjust index
            name: subCategoryName, // Subcategory name
            weight: subInfo.weight || "",
            reasoning: subInfo.reasoning || "",
            onChange: (value: number) =>
              handleWeightChange(
                categoryIndex,
                value,
                "sub_category",
                subCategoryName
              ),
          })
        );
      }) || [];

    return selectedType === "category" ? categoryData : subCategoryData;
  };

  const filteredData = getFilteredData();
  const progress = totalWeight;

  return (
    <>
      <div className="flex flex-col gap-6 sm:flex-row sm:justify-between">
        {/* Left Section */}
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-6">
          <Typography variant="Base" text="Define weights by" />
          <select
            className="px-2 py-1 w-[200px] border rounded bg-white outline-none focus:ring-0 focus:border-gray-300"
            value={selectedType}
            onChange={(e) =>
              setSelectedType(e.target.value as "category" | "sub_category")
            }
          >
            <option value="category">Category</option>
            <option value="sub_category">Subcategory</option>
          </select>
        </div>

        {/* Right Section */}
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-6">
          <Typography
            variant="Base"
            text="The total of weights must equal exactly 100%"
          />
          <div className="w-[340px] flex flex-col sm:flex-row items-center gap-4">
            <div className="relative w-full h-2 bg-[#0000000F] rounded">
              <div
                className="h-2 bg-blue-primary rounded"
                style={{
                  width: `${Math.min(totalWeight, 100)}%`,
                  transition: "width 0.5s ease-in-out",
                }}
              />
            </div>
            <Typography variant="Base" text={`${progress}%`} />
          </div>
        </div>
      </div>

      {totalWeight > 100 && (
        <Typography
          variant="Base"
          text={error}
          className="text-[red] text-right"
        />
      )}

      {isReasoningOpen && (
        <ReasoningBehindPopUp
          setIsReasoningOpen={setIsReasoningOpen}
          data={filteredData}
        />
      )}

      {/* Table */}
      <table className="w-full mt-4 table-fixed border-collapse border border-[#F0F0F0]">
        <colgroup>
          <col
            style={{ width: selectedType === "sub_category" ? "33%" : "50%" }}
          />
          {selectedType === "sub_category" && <col style={{ width: "33%" }} />}
          <col
            style={{ width: selectedType === "sub_category" ? "33%" : "50%" }}
          />
        </colgroup>
        <thead>
          <tr className="border border-[#F0F0F0]">
            {(selectedType === "sub_category"
              ? ["Category Name", "Subcategory Name", "Weight"]
              : ["Category Name", "Weight"]
            ).map((header, index) => (
              <th
                key={index}
                className="p-4 bg-[#FAFAFA] border border-[#F0F0F0] text-left"
              >
                <Typography
                  variant="Base"
                  text={header}
                  strong
                  className="w-fit"
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData?.map((item, index) => (
            <tr key={index} className="border border-[#F0F0F0]">
              {selectedType === "sub_category" && (
                <td className="p-4 border border-[#F0F0F0] whitespace-nowrap">
                  <Typography variant="Base" text={item.category} />
                </td>
              )}

              <td className="p-4 border border-[#F0F0F0] whitespace-nowrap">
                <Typography variant="Base" text={item.name} />
              </td>
              <td className="p-4 border border-[#F0F0F0]">
                <div className="relative w-[120px]">
                  <Input
                    placeholder="Weight"
                    value={
                      focusedField === index
                        ? Number(item.weight) === 0 ||
                          Number.isNaN(Number(item.weight))
                          ? ""
                          : String(item.weight) // Ensure it's a string
                        : Number(item.weight) === 0 ||
                            Number.isNaN(Number(item.weight))
                          ? ""
                          : `${item.weight}%` // Append "%" only when not focused
                    }
                    onChange={(e) => {
                      const value = e.target.value.replace("%", "");
                      if (value === "") {
                        item.onChange(0);
                      } else {
                        const numericValue = parseFloat(value);
                        if (!Number.isNaN(numericValue) && numericValue >= 0) {
                          item.onChange(numericValue);
                        }
                      }
                    }}
                    onFocus={() => setFocusedField(index)}
                    onBlur={() => setFocusedField(null)}
                    className="w-[120px] text-right"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Weight;
